import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAllContentfulData } from '../reducers/contentful.reducer';
import { selectSelectedAccount } from '../reducers/account.reducer';

export const useRegistriesOptions = (lang) => {
  const contentfulData = useSelector(selectAllContentfulData);
  const selectedAccount = useSelector(selectSelectedAccount);

  return useMemo(() => {
    if (!contentfulData?.registries || !selectedAccount?.actionRights?.registries) return [];
    const allowedRegistriesIds = selectedAccount.actionRights.registries.map(registry => registry.id);

    return contentfulData.registries
      .filter(registry => allowedRegistriesIds.includes(registry.id) && registry.lang === lang)
      .map(registry => ({ value: registry.id, label: registry.name }));
  }, [contentfulData, selectedAccount, lang]);
};

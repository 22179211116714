import { useEffect, useState } from 'react';
import { getLinksBetweenSurveysAndDocuments, getLinksBetweenSurveysAndRegistries } from '../reducers/contentful.reducer';
import { GENERATION_MODES } from '@utils/generate.constants';

export const useLinkedSurveys = (id, entityName, generationMode, contentfulData, selectedAccount) => {
  const [linkedSurveys, setLinkedSurveys] = useState([]);
  useEffect(() => {
    // petite sécurité si paramètre manquant
    if (!entityName) {
      setLinkedSurveys([]);
      return;
    }

    let isMounted = true; // pour éviter de setState si le composant est démonté

    (async () => {
      // Récupération asynchrone des "links"
      const links =
        generationMode === GENERATION_MODES.WORD ? await getLinksBetweenSurveysAndDocuments() : await getLinksBetweenSurveysAndRegistries();

      // Filtrage des surveys selon la logique existante
      let surveys = id
        ? contentfulData.surveys.filter(survey => (links.docsToSurveys || links.registriesToSurveys)?.[id]?.includes(survey.id))
        : contentfulData.surveys.filter(survey => selectedAccount.actionRights?.surveys?.some(userSurvey => userSurvey.id === survey.id));

      // Préparation du résultat final
      const final = surveys
        .map(survey => ({
          survey,
          versioning: survey.versioning,
          versions:
            selectedAccount.entities
              .find(entity => entity.name === entityName)
              ?.responses.find(response => response.surveyId === survey.id)
              ?.versions.map(version => version.versionName) || [],
        }))
        .filter(v => v.versioning.enabled && v.versions.length);

      if (isMounted) {
        setLinkedSurveys(final);
      }
    })();

    return () => {
      // cleanup pour éviter des setState sur composant démonté
      isMounted = false;
    };
  }, [id, entityName, generationMode, contentfulData, selectedAccount]);

  return linkedSurveys;
};

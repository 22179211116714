import styled from 'styled-components';
import { COLORS, COLORSV2 } from '../../utils/constants';
import { IconType } from 'react-icons';
import { useTranslation } from 'react-i18next';

export interface DataProps {
  icon: IconType;
  label: string;
}

export interface ConformityCardProps {
  data: Array<DataProps>;
  button?: {
    onClick: () => void;
    label: string;
    disabled?: boolean;
  };
  percentage: number;
  status?: string;
}

export const ConformityCard = ({ data, percentage }: ConformityCardProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ConformityCardContainer>
        <Top>
          <span>{t('conformityCard.title')}</span>
        </Top>
        <Bottom>
          <Global>
            <Percentage>{percentage}%</Percentage>
            <Label>{t('common.globalConformity')}</Label>
          </Global>
          {data.map((item, index) => {
            let Icon = item.icon;
            return (
              <Data key={index}>
                <GradientIcon>
                  <Icon />
                </GradientIcon>
                <Label>{item.label}</Label>
              </Data>
            );
          })}
        </Bottom>
      </ConformityCardContainer>
    </>
  );
};

const GradientIcon = styled.div`
  svg {
    width: 30px;
    height: 30px;
    color: ${COLORSV2.lightOrange};
  }
`;

const ConformityCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  padding: 15px 30px;
  border-radius: 16px;
  border: 1px solid ${COLORSV2.border};
  background: ${COLORSV2.white};
  box-shadow: 0px 4px 10px 0px ${COLORSV2.shadow};
  justify-content: space-around;
  row-gap: 8px;
  font-family: Biennale;
`;

const Global = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  > span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const Data = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const Label = styled.div`
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  color: ${COLORSV2.primary};
`;

const Top = styled.div`
  color: ${COLORS.darkGrey};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 0.5px solid #d9d9d9;
  padding-bottom: 8px;
`;

const Bottom = styled.div`
  display: flex;
  column-gap: 20px;
  width: 100%;
  justify-content: space-between;
`;

const Percentage = styled.div`
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${COLORSV2.white};
  background: ${COLORSV2.primaryBlue};
  border-radius: 5px;
  padding: 2px 10px;
`;
import { t } from 'i18next';
import { AiOutlineFileExcel, AiOutlineFileImage, AiOutlineFilePdf, AiOutlineFileWord } from 'react-icons/ai';

export const checkCustomActionFields = object => {
  const fieldsToCheck = ['title', 'description', 'complexity', 'category', 'urgency'];
  if (object.isEntityScoop) {
    fieldsToCheck.push('versionProductId');
  } else {
    fieldsToCheck.push('relatedProductId', 'versionProductId');
  }
  return fieldsToCheck.every(field => object[field] !== '' && object[field] !== undefined && object[field] !== null);
};

export const isAnyNewComment = (action, currentUserId) => {
  if (Array.isArray(action.comment) && action.comment.length > 0) {
    for (const comment of action.comment) {
      const currentUserSeenIndex = comment.seenComment.findIndex(seen => seen.userID === currentUserId);
      if (currentUserSeenIndex === -1) {
        return true;
      }
    }
  }
  return false;
};

export const getItemIcon = (type, onClick) => {
  switch (type) {
    case 'docx':
      return <AiOutlineFileWord onClick={onClick} />;
    case 'xlsx':
      return <AiOutlineFileExcel onClick={onClick} />;
    case 'pdf':
      return <AiOutlineFilePdf onClick={onClick} />;
    default:
      return <AiOutlineFileImage onClick={onClick} />;
  }
};

export const noTableDataAvailable = (selectedEntity, view) => {
  if (!selectedEntity) return t('actionPlan.select_entity_admin');
  switch (view) {
    case 'adminActions':
      return t('actionPlan.actions_unavailable_admin');
    case 'actionsPrepared':
      return t('actionPlan.prepared_actions_unavailable_admin');
    case 'backlog':
      return t('actionPlan.backlog_actions_unavailable_admin');
    default:
      return "Progression du plan d'action";
  }
};

export const getLabelByValue = (array, value) => {
  const item = array.find(item => item.value === value);
  return item ? item.label : '';
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  AdminStepsPageContainer,
  BasicModalContainer,
  ButtonContainer,
  Content,
  Description,
  EmptyEntity,
  EmptyMessages,
  EmptyStep,
  Label,
  LoaderContainer,
  MailContainer,
  MessageRow,
  MessageStatus,
  MessagesContainer,
  MessagesHeaders,
  OnboardingAction,
  Status,
  StatusIcon,
  Step,
  StepDetailsDrawer,
  StepDetailsTabs,
  StepInfos,
  StepTitle,
  StepInfosContainer,
  StepsContainer,
  StyledMdArrowForwardIos,
  Tab,
  Title,
  Top,
  TopInfos,
  TopContainer,
  Value,
} from './AdminSteps_.style';

import {
  PiAirplaneTakeoffThin,
  PiBriefcaseThin,
  PiCertificateThin,
  PiCheckBold,
  PiClipboardTextThin,
  PiFlagCheckered,
  PiListChecksThin,
  PiMapPinLight,
  PiPaperPlaneRightBold,
  PiXCircleBold,
  PiStampThin,
  PiRainbowThin,
  PiWarningLight,
} from 'react-icons/pi';
import { MdRefresh } from 'react-icons/md';

import { Button } from '../Button';
import { InputComponent } from '../form/InputComponent';
import { Modal } from '../modal/Modal';
import Loader from '../loader/loader';

import { refuseEntity, selectSelectedAccount, selectAccount, updateStep, validateAccount, validateEntity } from '../../reducers/account.reducer';

import { getEmailTemplate, getMessagesById } from '../../utils/api';
import { COLORS } from '../../utils/constants';

dayjs.locale('fr');

export const AmdinSteps = () => {
  const account = useSelector(selectAccount);
  const [activeStep, setActiveStep] = useState(undefined);
  const [selectedEntity, setSelectedEntity] = useState(undefined);
  const [templateHTML, setTemplateHTML] = useState(undefined);
  const [templateId, setTemplateId] = useState(undefined);
  const [messages, setMessages] = useState([]);
  const [currentTab, setCurrentTab] = useState('template');
  const selectedAccount = useSelector(selectSelectedAccount);
  const isAlreadyActivated = selectedAccount?.activatedAt !== undefined;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const hasLegalOfficer = selectedAccount?.legalOfficer?.name !== undefined && selectedAccount?.legalOfficer?.name !== '';

  const handleAccountActivation = async () => {
    dispatch(validateAccount({ accountId: selectedAccount._id }));
  };

  const accountSteps = [
    {
      name: 'onboarding',
      label: 'Onboarding',
      description: 'Cette étape est la première étape de la vie du client chez Dipeeo',
      isValidated: selectedAccount?.activatedAt !== undefined,
      validatedAt: selectedAccount?.activatedAt,
      validationLabel: 'Compte activé',
    },
  ];

  const parseHTML = html => {
    let parsedHTML = html.replace('14px', 'inherit');
    parsedHTML = parsedHTML?.replace('arial,helvetica,sans-serif', 'inherit');
    parsedHTML = parsedHTML?.replace('arial, helvetica, sans-serif', 'inherit');
    parsedHTML = parsedHTML?.replace('helvetica, sans-serif', 'inherit');
    parsedHTML = parsedHTML?.replace("'Chivo', sans-serif", 'inherit');
    return parsedHTML;
  };

  const fetchTemplate = async stepName => {
    let sendgridTemplate = await getEmailTemplate(stepName);
    if (!sendgridTemplate?.versions) {
      return;
    }
    let rawHTML = sendgridTemplate?.versions?.filter(version => version.active === 1)[0]?.html_content;
    setTemplateHTML(rawHTML ? parseHTML(rawHTML) : undefined);
    setTemplateId(sendgridTemplate.id);
    return sendgridTemplate.id;
  };

  const fetchMessages = async tempId => {
    let promises = selectedAccount.users.map(async user => {
      let mailSent = user.activity.sentMails.find(mail => mail.templateId === tempId);
      if (mailSent) {
        let sendgridMessages = await getMessagesById(mailSent.messageId).then(res => {
          if (res) {
            return res.messages;
          }
        });
        return sendgridMessages;
      }
    });
    let messagesResult = await Promise.all(promises).then(res => {
      return res.flat().filter(Boolean);
    });
    if (messagesResult.length !== 0) {
      setMessages(messagesResult);
    }
    return;
  };

  const refreshMessages = async () => {
    setLoading(true);
    await fetchMessages(templateId);
    setLoading(false);
  };

  const resetState = async () => {
    setCurrentTab('template');
    setTemplateId(undefined);
    setTemplateHTML(undefined);
    setMessages([]);
  };

  const handleStepClick = async step => {
    setLoading(true);
    await resetState();
    activeStep?.name === step.name ? setActiveStep(undefined) : setActiveStep(step);
    let tempId = await fetchTemplate(step.name);
    if (tempId) {
      await fetchMessages(tempId);
    }
    setLoading(false);
  };

  const getEntitiesOption = () =>
    selectedAccount?.entities?.map(entity => ({
      value: entity.name,
      label: entity.name,
    }));

  const handleEntitySelection = (name, value, id, e) => {
    const selectedEntity = selectedAccount?.entities?.find(entity => entity.name === value);
    if (selectedEntity) {
      setSelectedEntity(selectedEntity);
    }
    return;
  };

  const handleValidateStep = async isValidated => {
    if ((activeStep?.name === 'dpo' || activeStep?.name === 'surveys') && isValidated) {
      setShowModal(true);
      return;
    }
    await validateStep(isValidated);
    return;
  };

  const validateStep = async isValidated => {
    await dispatch(
      updateStep({
        adminId: account._id,
        accountId: selectedAccount._id,
        entityId: selectedEntity._id,
        stepId: activeStep._id,
        isValidated,
        activeStep: activeStep,
      })
    );
    if (activeStep.name === 'surveys') {
      if (isValidated) {
        await dispatch(
          validateEntity({
            adminId: account._id,
            accountId: selectedAccount._id,
            entityId: selectedEntity._id,
          })
        );
      } else {
        await dispatch(
          refuseEntity({
            adminId: account._id,
            accountId: selectedAccount._id,
            entityId: selectedEntity._id,
            reason: 'il y a un problème avec vos réponses',
          })
        );
      }
    }
    setShowModal(false);
    return;
  };

  const getIconByStep = stepName => {
    switch (stepName) {
      case 'surveys':
        return <PiClipboardTextThin />;
      case 'dpo':
        return <PiCertificateThin />;
      case 'validate':
        return <PiListChecksThin />;
      case 'onboarding':
        return <PiAirplaneTakeoffThin />;
      case 'action-plan':
        return <PiListChecksThin />;
      case 'maintenance':
        return <PiRainbowThin />;
      case 'label':
        return <PiStampThin />;
    }
  };

  const getStepActions = stepName => {
    switch (stepName) {
      case 'onboarding':
        return [
          {
            label: 'activer',
            onClick: handleAccountActivation,
            disabled: !hasLegalOfficer || isAlreadyActivated,
            icon: PiFlagCheckered,
            color: COLORS.DarkGreen,
          },
        ];
      case 'surveys':
        return [
          {
            label: 'valider',
            onClick: () => handleValidateStep(true),
            disabled: !selectedEntity.submission?.submitted && !selectedEntity?.submission?.rejected,
            icon: PiCheckBold,
            color: COLORS.DarkGreen,
          },
          {
            label: 'refuser',
            onClick: () => handleValidateStep(false),
            disabled: !selectedEntity.submission?.submitted && !selectedEntity?.submission?.validated,
            icon: PiXCircleBold,
            color: COLORS.softRed,
          },
        ];
      default:
        return [
          {
            label: 'valider',
            onClick: () => handleValidateStep(true),
            disabled: selectedEntity?.conformitySteps?.find(conformityStep => conformityStep?.name === stepName).isValidated,
            icon: PiPaperPlaneRightBold,
            color: COLORS.DarkGreen,
          },
          {
            label: 'annuler',
            onClick: () => handleValidateStep(false),
            disabled: !selectedEntity?.conformitySteps?.find(conformityStep => conformityStep?.name === stepName).isValidated,
            icon: PiXCircleBold,
            color: COLORS.softRed,
          },
        ];
    }
  };

  const getValidateTitle = () => {
    if (selectedEntity?.submission?.submitted) {
      return <span>Vous avez des réponses à vérifier</span>;
    }
    if (selectedEntity?.submission?.rejected) {
      return <span style={{ color: COLORS.softRed }}>En attente de modification des réponses</span>;
    }
    return;
  };

  const getStep = (step, index, bordered) => {
    const open = activeStep?.name === step.name;
    return (
      <Step bordered={bordered} key={index} disabled={step.name === 'label' || step.name === 'maintenance' || selectedAccount?.isArchived}>
        <TopContainer open={open} onClick={() => handleStepClick(step)}>
          <Status step={step} submission={selectedEntity?.submission} />
          <Top>
            {getIconByStep(step.name)}
            <TopInfos>
              <StepTitle>
                {step.label} {step.isValidated && <PiCheckBold />} {step.name === 'surveys' && getValidateTitle()}
              </StepTitle>
              <Description>{step.description}</Description>
              {step.isValidated && (
                <span>
                  {step.validationLabel ?? 'Étape activée'} le <b>{dayjs(step.validatedAt).format('D MMMM YYYY')}</b> à{' '}
                  <i>{dayjs(step.validatedAt).format('HH:mm')}</i>
                  {step.validatedBy?.name ? ` par ${step.validatedBy?.name}` : ''}
                </span>
              )}
            </TopInfos>
            <StyledMdArrowForwardIos open={open} className={'arrow'} />
          </Top>
        </TopContainer>
        <StepInfosContainer>
          <Status step={step} submission={selectedEntity?.submission} />
          <StepInfos open={open}>
            {getStepActions(step.name).map((action, index) => (
              <Button
                customColor={action.color}
                icon={action?.icon}
                disabled={action.disabled}
                key={index}
                onClick={action.onClick}
                label={action.label}
              />
            ))}
            {step.name === 'onboarding' && <OnboardingAction disabled={isAlreadyActivated}></OnboardingAction>}
          </StepInfos>
        </StepInfosContainer>
      </Step>
    );
  };

  useEffect(() => {
    if (!selectedEntity) {
      return;
    }
    let newEntity = selectedAccount.entities.find(entity => entity._id === selectedEntity._id);
    setSelectedEntity(newEntity);
  }, [selectedEntity, selectedAccount]);

  return (
    <>
      <AdminStepsPageContainer>
        <Content>
          <Title>Étapes de compte</Title>
          {accountSteps.map((step, index) => getStep(step, index, true))}
          <InputComponent
            onChange={handleEntitySelection}
            label={'Séléctionnez une entité'}
            type={'select'}
            options={getEntitiesOption()}
            sharable={false}
          />
          <Title>Étapes d'entités</Title>
          {selectedEntity?.conformitySteps ? (
            <StepsContainer>
              <>
                {selectedEntity?.conformitySteps?.map((step, index) => {
                  return getStep(step, index, false);
                })}
              </>
            </StepsContainer>
          ) : (
            <EmptyEntity>
              <EmptyStep>
                <PiBriefcaseThin />
                <span>Veuillez choisir une entité pour voir ses étapes</span>
              </EmptyStep>
            </EmptyEntity>
          )}
        </Content>
        <StepDetailsDrawer selectedStep={activeStep !== undefined}>
          {activeStep !== undefined ? (
            <>
              <StepDetailsTabs>
                <Tab current={currentTab === 'template'} onClick={() => setCurrentTab('template')}>
                  Prévisualisation du mail
                </Tab>
                <Tab current={currentTab === 'status'} onClick={() => setCurrentTab('status')}>
                  <span>Status des messages</span>
                  <Button label={'refresh'} outlined={true} icon={MdRefresh} onClick={() => refreshMessages()} disabled={!templateId} />
                </Tab>
              </StepDetailsTabs>
              {loading ? (
                <LoaderContainer>
                  <Loader loaderOnly={true} />
                </LoaderContainer>
              ) : (
                <>
                  {currentTab === 'status' ? (
                    <MessagesContainer>
                      {messages && messages?.length !== 0 && (
                        <MessagesHeaders>
                          <Label size={2}>STATUS</Label>
                          <Label size={5}>MESSAGE</Label>
                          <Label size={3}>LAST EVENT RECEIVED</Label>
                          <Label size={1}>OPENS</Label>
                          <Label size={1}>CLICKS</Label>
                        </MessagesHeaders>
                      )}
                      {messages && messages?.length !== 0 ? (
                        messages.map((message, index) => {
                          return (
                            message && (
                              <MessageRow key={index}>
                                <MessageStatus size={2}>
                                  <StatusIcon status={message['status']} />
                                  {message['status']}
                                </MessageStatus>
                                <Value size={5}>To: {message['to_email']}</Value>
                                <Value size={3}>{message['last_event_time']}</Value>
                                <Value size={1}>{message['opens_count']}</Value>
                                <Value size={1}>{message['clicks_count']}</Value>
                              </MessageRow>
                            )
                          );
                        })
                      ) : (
                        <EmptyMessages>
                          <PiWarningLight />
                          <span>Aucun message n'a été envoyé.</span>
                          <span>Plusieurs minutes sont nécessaires à la mise à jour des informations</span>
                          <span>Cliquez sur le bouton pour raffraichir les données.</span>
                        </EmptyMessages>
                      )}
                    </MessagesContainer>
                  ) : (
                    <>
                      {templateHTML ? (
                        <MailContainer dangerouslySetInnerHTML={{ __html: templateHTML }}></MailContainer>
                      ) : (
                        <EmptyMessages>
                          <PiWarningLight />
                          <span>Aucun template n'existe pour cette étape</span>
                        </EmptyMessages>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <EmptyStep>
              <PiMapPinLight />
              <span>Vous n'avez pas encore séléctionné d'étape</span>
            </EmptyStep>
          )}
        </StepDetailsDrawer>
      </AdminStepsPageContainer>
      <Modal
        alignment={'center'}
        size={{ width: '30%' }}
        show={showModal}
        title={`Êtes-vous sûr·e de vouloir valider l'étape <b>${activeStep?.label}</b> ?`}
        onClose={() => setShowModal(false)}>
        <BasicModalContainer>
          <p>Cette action entrainera l'envoi d'un mail d'information au client. </p>
          <ButtonContainer>
            <Button type='text' onClick={() => setShowModal(false)} label={'Annuler'} />
            <Button type='primary' onClick={() => validateStep(true)} label={'Valider'} />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
    </>
  );
};

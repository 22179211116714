import { COLORSV2 } from '../../utils/constants';
import styled, { css } from 'styled-components';
import { MdOutlineLightbulb } from 'react-icons/md';
import { IconType } from 'react-icons';
import { Button } from '../Button';

export interface InfosProps {
  id?: string;
  text: string;
  icon?: IconType;
  action?: {
    text: string;
    onClick: () => void;
  };
  className?: string;
}

export const Infos = ({ icon, text, action, className, id }: InfosProps) => {
  let Icon = icon ?? MdOutlineLightbulb;

  return (
    <InfosContainer id={id} customIcon={icon} className={className}>
      {icon && <Icon />}
      <span dangerouslySetInnerHTML={{ __html: text }}></span>
      {action && <Button type='outlined' label={action.text} onClick={action.onClick} />}
    </InfosContainer>
  );
};

const InfosContainer = styled.div<{ id?: string; customIcon: IconType }>`
  border-radius: 20px;
  background-color: ${COLORSV2.lightPink};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-family: 'Nexa Regular';
  font-weight: 400;
  animation: fadeInUp 0.3s ease-in-out;
  position: relative;
  row-gap: 20px;

  span {
    ${({ id }) =>
      id === 'tooltip-infos' &&
      css`
        color: ${COLORSV2.lightOrange};
      `}
  }

  svg {
    position: absolute;
    font-size: 40px;
    ${({ customIcon }) =>
      !customIcon &&
      css`
        transform: rotate(10deg);
      `}
    color: ${COLORSV2.lightOrange};
    top: -15px;
    right: 0px;
    transform: rotate(15deg);
  }
`;

import styled from 'styled-components';

import { COLORS, COLORSV2 } from '@utils/constants';

export const TabContainer = styled.div<{ tabsUnderlined: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: ${({ tabsUnderlined }) => (tabsUnderlined ? '1rem' : '4rem')};
`;

export const Tab = styled.div<{ selected: boolean }>`
  position: relative;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) => (selected ? '#326c82' : 'white')};
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: 500;
  border: ${COLORS.MediumGrey} solid 1px;
  border-radius: 5px;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    background-color: ${COLORS.darkBlue};
    color: white;
  }
`;

export const TabUnderline = styled.div<{ selected: boolean }>`
  position: relative;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ selected }) => (selected ? COLORSV2.lightOrange : COLORSV2.primary)};
  font-family: 'Biennale';
  font-size: 1.7em;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  border-radius: 5px;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    color: ${COLORSV2.lightOrange};
  }

  ${({ selected }) =>
    selected &&
    `&::after {
      content: "";
      width: 80%;
      height: .1em;
      background: ${COLORSV2.lightOrange};
      position: absolute;
      bottom: 10px;
      left: 10%;
   }`}
`;

export const TabCount = styled.div<{ selected: boolean }>`
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translate(50%, -50%);
  background-color: ${({ selected }) => (selected ? 'white' : '#326c82')};
  color: ${({ selected }) => (selected ? '#326c82' : 'white')};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  border: 1px solid ${({ selected }) => (selected ? '#326c82' : 'white')};
  transition: all 0.4s ease;
`;

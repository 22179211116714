import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Tippy from '@tippyjs/react';

import { MenuItem, Avatar, Checkbox, TextField, FormControlLabel } from '@mui/material';

import { AiOutlineFileAdd } from 'react-icons/ai';
import { VscDebugStepOver } from 'react-icons/vsc';
import { RiDeleteBin2Line, RiDeleteBinLine } from 'react-icons/ri';
import { PiListMagnifyingGlassDuotone } from 'react-icons/pi';

import { Chip, Container, FilesIcons, Tabs } from '@components-new/atoms';
import { FiltersMenu, PageWrapper } from '@components-new/molecules';
import { DataTable } from '@components-new/organisms';

import { dataTableColumnsProps } from '@components-new/common/types';
import { actionsStatus, actionsUrgencyComplexity } from '@components-new/common/constants';

// TODO : to be moved to UI lib
import ActionDetails from './components/ActionDetails';

import { FileExplorer } from '@components/file-explorer/FileExplorer';
import { Modal } from '@components/modal/Modal';
import { Button } from '@components/Button';

import { fetchQuestionKeysWithTheirFinality, selectQuestionsFinality, selectUserActions } from 'src/reducers/contentful.reducer';
import { selectDisplayDebug, setLoading } from 'src/reducers/app.reducer';
import {
  updateActionPlan,
  createCustomAction,
  selectUser,
  selectSelectedAccount,
  selectActiveAccount,
  selectImpersonated,
  selectEntityActions,
  clearEntityActions,
  fetchEntityActions,
  editCustomAction,
  editDirectoryCustomAction,
  deleteCustomAction,
  selectDirectoryActions,
  fetchDirectoryActions,
  deleteDirectoryCustomAction,
} from 'src/reducers/account.reducer';

import useDidUpdate from 'src/hooks/useDidUpdate';
import {
  compareByStatus,
  compareByUrgency,
  compareQueryToActionName,
  getAuthorizedProducts,
  getFileNameFromKey,
  getVersionIdByName,
  getVersionName,
  getFullActionPlans,
  retrieveUserDetails,
  stringAvatar,
} from '@utils/utils';

import { ActionProps, CustomActionProps, EntityProps, FiltersProps } from './ActionPlan.types';
import { category, urgencyComplexity, status, customActionInitialState, filtersInitialState, subCategory } from './ActionPlan.constants';

import {
  AvatarContainer,
  LinkField,
  ErasePlaceholder,
  ActionContainer,
  ModalContent,
  AdjustActions,
  ButtonsContainer,
  FloatingActionBar,
  CheckedElements,
  ConfirmContainer,
  CustomStack,
  SeeOnContentful,
  CheckCustomActionDirectory,
  ExpandFieldIcon,
} from './ActionPlan_.style';
import { checkCustomActionFields, getItemIcon, getLabelByValue, isAnyNewComment, noTableDataAvailable } from './ActionPlan.utils';
import { downloadActionPlanBuffer } from '@utils/api';
import { saveAs } from 'file-saver';
import { ChatBubbleIcon, Infos } from '@pages/ActionPlanDash/ActionPlanDash_.styles';
import { GoPencil } from 'react-icons/go';
import { HiOutlineDuplicate } from 'react-icons/hi';
import ActionDirectory from './components/ActionDirectory';
import { COLORS } from '@utils/constants';
import ActionPlanHeader from './components/ActionPlanHeader';

const AdminActionPlan = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch<any>();
  const impersonated = useSelector(selectImpersonated);
  const currentUser = useSelector(selectUser);
  const selectedAccount = useSelector(selectSelectedAccount);
  const activeAccount = useSelector(selectActiveAccount);
  const userActions = useSelector(selectUserActions);
  const questionsWithFinality = useSelector(selectQuestionsFinality);
  const entityActions = useSelector(selectEntityActions);
  const displayDebug = useSelector(selectDisplayDebug);
  const directoryActions = useSelector(selectDirectoryActions);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [view, setView] = useState({ label: t('views.backlog'), value: 'backlog' });
  const [query, setQuery] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<ActionProps>({});
  const [editedAction, setEditedAction] = useState<ActionProps>({});
  const [selectedEntity, setSelectedEntity] = useState<EntityProps>(null);
  const [selectAllActions, setSelectAllActions] = useState<boolean>(false);
  const [customAction, setCustomAction] = useState<CustomActionProps>(customActionInitialState);
  const [filters, setFilters] = useState<FiltersProps>(filtersInitialState);
  const [filtersMenAnchorEl, setFiltersMenAnchorEl] = useState<null | HTMLElement>(null);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showCustomActionModal, setShowCustomActionModal] = useState<boolean>(false);
  const [showAvailableDocuments, setShowAvailableDocuments] = useState({
    action: undefined,
    show: false,
    context: undefined,
  });
  const [description, setDescription] = useState<string>('');
  const [explanation, setExplanation] = useState<string>('');
  const [precision, setPrecision] = useState<string>('');
  const [showFullContentModal, setShowFullContentModal] = useState<boolean>(false);
  const [fullContentModalTitle, setFullContentModalTitle] = useState<string>('');
  const [fullContentModalValue, setFullContentModalValue] = useState<string>('');
  const [fullContentModalField, setFullContentModalField] = useState<string>('');
  const [selectedDrawerItemID, setSelectedDrawerItemID] = useState<string | null>(null);
  const [checkedActions, setCheckedActions] = useState<Array<ActionProps>>([]);
  const [editAction, setEditAction] = useState<boolean>(false);
  const [editDirectoryAction, setEditDirectoryAction] = useState<boolean>(false);
  const [duplicateAction, setDuplicateAction] = useState<boolean>(false);
  const [addActionFromDirectory, setAddActionFromDirectory] = useState<boolean>(false);
  const [deleteAction, setDeleteAction] = useState<ActionProps | null>(null);
  const [deleteActionInDirectory, setDeleteActionInDirectory] = useState<ActionProps | null>(null);
  const [expandedCustom, setExpandedCustom] = useState(false);

  const deleteDocumentRef = useRef<HTMLDivElement>(null);
  const discussionRef = useRef<HTMLDivElement>(null);

  const views = useMemo(() => {
    return [
      {
        label: t('views.backlog'),
        value: 'backlog',
        onClick: () => {
          setView({ label: t('views.backlog'), value: 'backlog' });
        },
      },
      {
        label: t('views.prepared_actions'),
        value: 'actionsPrepared',
        onClick: () => {
          setView({ label: t('views.prepared_actions'), value: 'actionsPrepared' });
        },
      },
      {
        label: t('views.action_plan'),
        value: 'adminActions',
        onClick: () => {
          setView({ label: "Plan d'actions", value: 'adminActions' });
        },
      },
    ];
  }, []);

  const handleFilterChange = (selectedOptions, name) => {
    setFilters(prevState => ({ ...prevState, [name]: selectedOptions }));
  };

  const handleFiltersClick = (event: React.MouseEvent<HTMLElement>) => {
    setFiltersMenAnchorEl(event.currentTarget);
  };

  const handleFiltersReset = () => {
    setFilters({ urgency: [], status: [], category: [], product: [], complexity: [] });
  };

  const filteredData = data => {
    return (
      data
        ?.filter((item: ActionProps) => {
          switch (view.value) {
            case 'backlog':
              return !item.draft && !item.active;
            case 'actionsPrepared':
              return item.draft && !item.active;
            case 'adminActions':
              return !['Demande', 'Audit'].includes(item.category) && item.active === true;
            case 'requests':
              return item.category === 'Demande';
            default:
              return true; // Include all items if view is not recognized
          }
        })
        // Apply search filter
        .filter((item: ActionProps) => {
          if (item.category === 'Audit' && !item.custom) {
            const title = t('actionPlan.audit_action', { name: item?.subContractor?.name });
            return compareQueryToActionName(title, item.actionId, query);
          }
          return compareQueryToActionName(item.title, item.actionId, query);
        })
        .filter((item: ActionProps) => {
          const { urgency, status, category, product, complexity } = filters;
          // Apply urgency filter
          if (urgency.length > 0 && !urgency.some(option => option.value === item.urgency)) {
            return false;
          }
          // Apply status filter
          if (status.length > 0 && !status.some(option => option.value === item.status)) {
            return false;
          }
          // Apply category filter
          if (category.length > 0 && !category.some(option => option.value === item.category)) {
            return false;
          }
          // Apply product filter
          if (
            product.length > 0 &&
            !product.some(option => {
              if (item.isEntityScoop) {
                return option.value === item.versionProductId;
              } else {
                if (item.versionProductId) {
                  return option.value === item.versionProductId;
                }
                if (item.versionProductName && !item.versionProductId) {
                  const versionId = getVersionIdByName(selectedEntity?._id, item.relatedProductId, item.versionProductName, true);
                  return option.value === versionId;
                }
              }
              return false;
            })
          ) {
            return false;
          }
          // Apply complexity filter
          if (complexity.length > 0 && !complexity.some(option => option.value === item.complexity)) {
            return false;
          }
          return true;
        })
        .sort(compareByUrgency)
        .sort(compareByStatus)
    );
  };

  const filteredDataCustoms = data => {
    return (
      data
        // Apply search filter
        .filter((item: ActionProps) => {
          return compareQueryToActionName(item.title, item.actionId, query);
        })
        .filter((item: ActionProps) => {
          const { urgency, category, complexity } = filters;
          // Apply urgency filter
          if (urgency.length > 0 && !urgency.some(option => option.value === item.urgency)) {
            return false;
          }
          // Apply category filter
          if (category.length > 0 && !category.some(option => option.value === item.category)) {
            return false;
          }
          // Apply complexity filter
          if (complexity.length > 0 && !complexity.some(option => option.value === item.complexity)) {
            return false;
          }
          return true;
        })
    );
  };

  const rawAdminData = useMemo(() => {
    return getFullActionPlans(selectedEntity, selectedAccount, userActions, entityActions, questionsWithFinality);
  }, [selectedEntity, userActions, entityActions]);

  const adminData = useMemo(() => {
    setSelectAllActions(false);
    setCheckedActions([]);
    if (selectedEntity && rawAdminData?.length) {
      return filteredData(rawAdminData);
    }
    return [];
  }, [rawAdminData, filters, view, query]);

  useEffect(() => {
    if (selectAllActions) {
      setCheckedActions([...adminData]);
    } else {
      setCheckedActions([]);
    }
  }, [selectAllActions]);

  useDidUpdate(async () => {
    if (!questionsWithFinality || !questionsWithFinality.length) dispatch(fetchQuestionKeysWithTheirFinality());
    if (entityActions) dispatch(clearEntityActions());
    if (selectedEntity?._id) {
      if (!isDataLoading) setIsDataLoading(true);
      let data = { accountId: null, entityId: selectedEntity?._id };
      if (impersonated?._id) {
        data.accountId = impersonated?._id;
      } else if (selectedAccount?._id) {
        data.accountId = selectedAccount?._id;
      } else if (activeAccount?._id) {
        data.accountId = activeAccount?._id;
      }
      await dispatch(fetchEntityActions(data));
      setIsDataLoading(false);
    }
  }, [selectedEntity?._id]);

  useEffect(() => {
    dispatch(fetchDirectoryActions());
  }, []);

  const customActionsData = useMemo(() => {
    return filteredDataCustoms(directoryActions);
  }, [directoryActions, filters, view, query]);

  //
  // Action handlers
  //

  const handleCheckedActions = (event, action, type?, isEntityScoop: boolean = false) => {
    const { id, relatedProductId, versionProductId, subContractor } = action;

    let updatedAdminFiltredActions = [...adminData].map(item => ({ ...item }));
    const itemIndex = updatedAdminFiltredActions.findIndex(
      isEntityScoop
        ? item => item.id === id && item.versionProductId === selectedEntity?._id
        : action.category === 'Audit'
          ? item =>
              item.id === id &&
              item.relatedProductId === relatedProductId &&
              item.versionProductId === versionProductId &&
              item.subContractor.name === subContractor.name
          : item => item.id === id && item.relatedProductId === relatedProductId && item.versionProductId === versionProductId
    );

    if (itemIndex !== -1) {
      if (type === 'link') {
        const { link } = updatedAdminFiltredActions[itemIndex];
        if (event.target.value !== null && event.target.value !== '') {
          if (link !== '' && link !== null && link !== undefined) {
            const linkArray = link.split(';');
            updatedAdminFiltredActions[itemIndex].link = linkArray.includes(event.target.value)
              ? linkArray.filter(link => link !== event.target.value).join(';')
              : linkArray.concat(event.target.value).join(';');
          } else {
            const newUpdatedItem = { ...updatedAdminFiltredActions[itemIndex], link: event.target.value };
            updatedAdminFiltredActions[itemIndex] = newUpdatedItem;
          }
        } else {
          const newUpdatedItem = { ...updatedAdminFiltredActions[itemIndex], link: '' };
          updatedAdminFiltredActions[itemIndex] = newUpdatedItem;
        }
      }
    } else {
      updatedAdminFiltredActions.push({
        ...(isEntityScoop
          ? {
              isEntityScoop: true,
              versionProductId: selectedEntity?._id,
            }
          : {
              relatedProductId,
              versionProductId,
              versionProductName: getVersionName(selectedEntity._id, relatedProductId, versionProductId),
            }),
        ...action,
        link: type === 'link' ? event.target.value : null,
      });
    }
    dispatch(setLoading(true));

    if (event.target.checked && event.target.value === '' && type === 'link') {
      updatedAdminFiltredActions = updatedAdminFiltredActions[itemIndex];
    }

    dispatch(
      updateActionPlan({
        isAdmin: true,
        accountId: selectedAccount._id,
        entityId: selectedEntity._id,
        actions: updatedAdminFiltredActions,
      })
    );

    dispatch(setLoading(false));
  };

  const getCheckedActions = action => {
    const checkedAction = checkedActions.find(checkedAction => {
      if (action.category === 'Audit') {
        return (
          checkedAction.id === action.id &&
          checkedAction.relatedProductId === action.relatedProductId &&
          checkedAction.versionProductId === action.versionProductId &&
          checkedAction.subContractor?.name === action.subContractor?.name
        );
      }
      return (
        checkedAction.id === action.id &&
        checkedAction.relatedProductId === action.relatedProductId &&
        checkedAction.versionProductId === action.versionProductId
      );
    });
    return checkedAction ? true : false;
  };

  const handleShowActionDetail = (task, ItemID) => {
    const { id, relatedProductId, versionProductId } = task;
    const targetTask = adminData.find(action => {
      return relatedProductId
        ? action.id === id && action.relatedProductId === relatedProductId && action.versionProductId === versionProductId
        : action.id === id && action.versionProductId === selectedEntity._id.toString();
    });

    setSelectedAction(targetTask);
    setEditedAction(targetTask);
    setSelectedDrawerItemID(ItemID);
    setShowDrawer(prevState => !prevState);
  };

  const handleUpdateAuthorizedActions = upgrade => {
    dispatch(setLoading(true));
    const actions = checkedActions.map(elt => {
      const updatedAction = {
        ...(elt.isEntityScoop
          ? {
              isEntityScoop: true,
              versionProductId: selectedEntity?._id,
            }
          : {
              versionProductName: getVersionName(selectedEntity._id, elt.relatedProductId, elt.versionProductId),
            }),
        ...elt,
      };
      const propertiesToDelete = ['responsible', 'sentMails', 'createdAt', 'conditions', 'checkbox', 'product'];
      for (const property of propertiesToDelete) {
        delete updatedAction?.[property];
      }
      // Delete special data for table => attributes that ends with 'Col' => prevent unwanted action data modification
      Object.keys(updatedAction).forEach(key => {
        if (key.endsWith('Col')) {
          delete updatedAction[key];
        }
      });
      return updatedAction;
    });

    dispatch(
      updateActionPlan({
        isAdmin: true,
        accountId: selectedAccount._id,
        entityId: selectedEntity._id,
        actions,
        upgrade: upgrade,
      })
    );

    dispatch(setLoading(false));
  };

  //
  // Custom Action handlers
  //

  const handleCustomDataChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    if (name === 'description') {
      setDescription(value);
    } else if (name === 'explanation') {
      setExplanation(value);
    } else if (name === 'precision') {
      setPrecision(value);
    }
    setCustomAction(prevState => {
      let updatedState;

      if (name === 'versionProductId') {
        if (value === selectedEntity._id) {
          updatedState = {
            ...prevState,
            [name]: value,
            isEntityScoop: true,
          };
          if (updatedState.relatedProductId) delete updatedState.relatedProductId;
        } else {
          const actionProductDetails = getProductsOptions()?.find(elt => elt.value === value);
          if (name === 'versionProductId' && actionProductDetails) {
            const { product, label, value } = actionProductDetails;
            updatedState = {
              ...prevState,
              relatedProductId: product,
              versionProductName: label,
              versionProductId: value,
            };
          }
        }
      } else {
        updatedState = {
          ...prevState,
          [name]: value,
        };
      }

      return {
        ...updatedState,
        isReady: checkCustomActionFields(updatedState),
      };
    });
  };

  const handleCreateCustomAction = () => {
    dispatch(setLoading(true));
    delete customAction.isReady;
    dispatch(
      createCustomAction({
        accountId: selectedAccount._id,
        entityId: selectedEntity._id,
        action: customAction,
      })
    ).then(() => {
      dispatch(fetchEntityActions({ accountId: selectedAccount._id, entityId: selectedEntity._id }));
    });
    dispatch(setLoading(false));
    setShowCustomActionModal(prevState => !prevState);
    setCustomAction({
      title: '',
      relatedProductId: '',
      description: '',
      explanation: '',
      precision: '',
      complexity: '',
      urgency: '',
      conditions: [],
      category: '',
      custom: true,
      isReady: false,
      proof: {},
      addDirectory: false,
    });
    setDescription('');
    setExplanation('');
    setPrecision('');
    setAddActionFromDirectory(false);
    setDuplicateAction(false);
  };

  const handleEditCustomAction = (isDirectory: boolean = false) => {
    dispatch(setLoading(true));
    delete customAction.isReady;
    isDirectory
      ? dispatch(
          editDirectoryCustomAction({
            action: customAction,
          })
        )
      : dispatch(
          editCustomAction({
            accountId: selectedAccount._id,
            entityId: selectedEntity._id,
            action: customAction,
          })
        );
    dispatch(setLoading(false));
    setShowCustomActionModal(prevState => !prevState);
    setCustomAction({
      title: '',
      relatedProductId: '',
      description: '',
      explanation: '',
      precision: '',
      complexity: '',
      urgency: '',
      conditions: [],
      category: '',
      subCategory: '',
      custom: true,
      isReady: false,
    });
    setEditAction(false);
    setEditDirectoryAction(false);
    setDescription('');
    setExplanation('');
    setPrecision('');
  };

  const handleDeleteCustomAction = (isDirectory: boolean = false) => {
    isDirectory
      ? dispatch(
          deleteDirectoryCustomAction({
            action: deleteActionInDirectory,
          })
        )
      : dispatch(
          deleteCustomAction({
            accountId: selectedAccount._id,
            entityId: selectedEntity._id,
            actionId: deleteAction.id,
            relatedProductId: deleteAction.relatedProductId,
            versionProductId: deleteAction.versionProductId,
            directoryActionId: deleteAction.directoryActionId,
          })
        );
    setDeleteAction(null);
    setDeleteActionInDirectory(null);
  };

  const getProductsOptions = () => {
    const products = [];
    const productsList = getAuthorizedProducts()
      .filter(product => product.versioning.enabled)
      .map(product => product.id);
    selectedAccount.entities
      .find(entity => entity._id === selectedEntity?._id)
      ?.responses?.forEach(response => {
        return (
          productsList.includes(response.surveyId) &&
          response?.versions?.forEach(version =>
            products.push({
              label: version?.versionName,
              value: version?._id,
              product: response.surveyId,
            })
          )
        );
      });
    return products;
  };

  //
  // Utils
  //

  const getActionLink = (action: ActionProps) => {
    return (
      checkedActions?.find(checkedAction => {
        if (checkedAction.isEntityScoop) {
          return checkedAction.id === action.id && checkedAction.versionProductId === action.versionProductId;
        } else {
          return (
            checkedAction.id === action.id &&
            checkedAction.versionProductId === action.versionProductId &&
            checkedAction.relatedProductId === action.relatedProductId
          );
        }
      })?.link || action?.link
    );
  };

  const handleDuplicateAction = (action: ActionProps) => {
    const { title, description, complexity, urgency, category, explanation, precision, conditions, draft, proof } = action;
    setDescription(description);
    setExplanation(explanation);
    setPrecision(precision);
    setDuplicateAction(true);

    const newProof = { required: proof?.required };

    setCustomAction({
      title,
      description,
      complexity,
      urgency,
      category,
      precision,
      explanation,
      conditions,
      custom: true,
      isReady: false,
      draft,
      proof: newProof,
    });
    setShowCustomActionModal(true);
  };

  const handleEditAction = (action: ActionProps, isDirectory: boolean = false) => {
    const {
      id,
      title,
      description,
      complexity,
      urgency,
      category,
      subCategory,
      explanation,
      precision,
      conditions,
      draft,
      versionProductId,
      relatedProductId,
    } = action;
    setDescription(description);
    setExplanation(explanation);
    setPrecision(precision);
    isDirectory ? setEditDirectoryAction(true) : setEditAction(true);
    setCustomAction({
      id,
      title,
      description,
      complexity,
      urgency,
      category,
      subCategory,
      precision,
      explanation,
      conditions,
      versionProductId,
      relatedProductId,
      custom: true,
      isReady: false,
      draft,
      directoryActionId: action.directoryActionId,
    });
    setShowCustomActionModal(true);
  };

  const handleSelectActions = action => {
    const actionIndex = checkedActions.findIndex(checkedAction => {
      if (action.category === 'Audit') {
        return (
          checkedAction.id === action.id &&
          checkedAction.relatedProductId === action.relatedProductId &&
          checkedAction.versionProductId === action.versionProductId &&
          checkedAction.subContractor?.name === action.subContractor?.name
        );
      }
      return (
        checkedAction.id === action.id &&
        checkedAction.relatedProductId === action.relatedProductId &&
        checkedAction.versionProductId === action.versionProductId
      );
    });

    if (actionIndex !== -1) {
      const actions = [...checkedActions];
      actions.splice(actionIndex, 1);
      setCheckedActions(actions);
    } else {
      setCheckedActions(prevCheckedActions => [...prevCheckedActions, action]);
    }
  };

  //
  // handle focus on link input
  //
  const handleFocusLinkInput = (e, action, context = undefined) => {
    e.stopPropagation();
    setShowAvailableDocuments({ action, show: true, context });
  };

  const handleFileChoice = fileKey => {
    if (showAvailableDocuments.context === 'table') {
      handleCheckedActions(
        { target: { checked: true, value: fileKey } },
        showAvailableDocuments.action,
        'link',
        showAvailableDocuments.action.isEntityScoop
      );
    } else {
      handleCustomDataChange({ target: { name: 'link', value: fileKey } });
    }
    setShowAvailableDocuments({ action: undefined, show: false, context: undefined });
  };

  const handleCustomActionModalClose = e => {
    setCustomAction(customActionInitialState);
    setShowCustomActionModal(false);
    setDescription('');
    setExplanation('');
    setPrecision('');
    setAddActionFromDirectory(false);
    setDuplicateAction(false);
    setEditAction(false);
    setEditDirectoryAction(false);
  };

  const getEntryUrl = (entryId: string) => {
    return `https://app.contentful.com/spaces/5h3y0cofo0sv/environments/${process.env.REACT_APP_CONTENTFUL_TARGET_ENVIRONMENT}/entries/` + entryId;
  };

  const adminColumns: dataTableColumnsProps[] = [
    {
      field: 'titleCol',
      headerName: t('common.action'),
      width: 'col-1-5',
      bolder: true,
      center: false,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'product',
      headerName: t('common.product_entity'),
      width: 'col-1',
      bolder: true,
      center: false,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'statusCol',
      headerName: t('common.status'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'dueDateCol',
      headerName: t('common.due_date'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'urgencyCol',
      headerName: t('common.criticality'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'complexityCol',
      headerName: t('common.complexity'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'categoryCol',
      headerName: t('common.category'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'responsibleCol',
      headerName: t('common.responsible'),
      width: 'col-1',
      bolder: true,
      center: false,
      placeholder: { variant: 'rounded', width: 100 },
    },
    {
      field: 'documentCol',
      headerName: t('common.document'),
      width: 'col-1',
      bolder: true,
      center: false,
      placeholder: { variant: 'rounded', width: 80 },
    },
    {
      field: 'proofCol',
      headerName: t('common.proof') + ' / @',
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'rounded', width: 80 },
      // renderCell: params => {
      //   return params.row.proof && params.row.proof.required && (params.row.proof.link || params.row.proof.documents) ? (
      //     <PiListMagnifyingGlassDuotone size={25} />
      //   ) : null;
      // },
    },
  ];

  const filteredAdminColumns = view.value === 'adminActions' ? adminColumns : adminColumns.filter(col => col.field !== 'proofCol');

  const isMessageForLegalOfficerUnread = action => {
    if (selectedAccount.legalOfficer.name !== activeAccount.companyName) {
      return false;
    }
    if (Array.isArray(action.comment) && action.comment.length > 0) {
      for (const comment of action.comment) {
        if (comment.isLegalOfficerMentioned) {
          const currentUserSeenIndex = comment.seenComment.findIndex(seen => seen.userID === currentUser._id);
          if (currentUserSeenIndex === -1) {
            return true;
          }
          if (comment.seenComment.some(seenComment => seenComment.userID === currentUser._id && !seenComment.seen)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const adminRowAction = useMemo(
    () =>
      view.value !== 'adminActions'
        ? [
            {
              icon: GoPencil,
              label: t('common.edit'),
              onClick: row => {
                handleEditAction(row, false);
              },
              showInMenu: true,
              isDisabled: row => !row.custom,
            },
            {
              icon: HiOutlineDuplicate,
              label: t('common.duplicate'),
              onClick: row => {
                handleDuplicateAction(row);
              },
              showInMenu: true,
              isDisabled: row => row.category === 'Audit',
            },
            {
              icon: RiDeleteBinLine,
              label: t('common.delete'),
              color: '#FF746F',
              onClick: row => {
                setDeleteAction(row);
              },
              showInMenu: true,
              isDisabled: row => !row.custom,
              separator: true,
            },
          ]
        : [],
    [view]
  );

  const adminRows = useMemo(() => {
    if (selectedEntity) {
      return adminData.map(action => {
        return {
          ...action,
          titleCol:
            action.category === 'Audit'
              ? action.custom
                ? action?.title
                : t('actionPlan.audit_action', { name: action?.subContractor?.name, finality: action?.finality })
              : action?.title,
          product: action.isEntityScoop ? selectedEntity.name : action.versionProductName,
          checkbox: () => {
            return (
              <Checkbox
                checked={getCheckedActions(action)}
                onClick={e => {
                  e.stopPropagation();
                }}
                onChange={e => handleSelectActions(action)}
              />
            );
          },
          statusCol: () => {
            const { label, color, icon } = actionsStatus[action.status] || {};
            return <Chip variant='text' label={t(label) || t('common.not_activated')} color={color} icon={icon} />;
          },
          dueDateCol: action.dueDate ? dayjs(action.dueDate, 'DD-MM-YYYY').format('DD-MM-YYYY').toString() : '-- / -- / ----',
          urgencyCol: () => {
            const { label, color, backgroundColor } = actionsUrgencyComplexity[action?.urgency];
            return <Chip variant='outlined' label={t(label)} color={color} backgroundColor={backgroundColor} />;
          },
          complexityCol: () => {
            const { label, color, backgroundColor } = actionsUrgencyComplexity[action?.complexity];
            return <Chip variant='outlined' label={t(label)} color={color} backgroundColor={backgroundColor} />;
          },
          categoryCol: () => {
            return <Chip label={getLabelByValue(category, action?.category)} />;
          },
          responsibleCol: () => {
            return (
              <AvatarContainer max={2}>
                {action?.responsible?.map((responsibleId, index) => {
                  const { name, email } = retrieveUserDetails(responsibleId, selectedAccount);
                  return <Avatar key={index} {...stringAvatar((name ?? email).toUpperCase(), responsibleId)} />;
                })}
              </AvatarContainer>
            );
          },
          documentCol: () => {
            const actionLink = getActionLink(action);
            return (
              <CustomStack direction='row' spacing={2}>
                <LinkField type={actionLink?.split('.')?.pop()}>
                  {(action.draft || action.active) && (actionLink === '' || actionLink === undefined || actionLink === null) && (
                    <div className='col-0-5'>
                      <AiOutlineFileAdd className={'add'} onClick={e => handleFocusLinkInput(e, action, 'table')} />
                      <ErasePlaceholder />
                    </div>
                  )}
                  {actionLink !== '' && actionLink !== null && actionLink !== undefined && (
                    <>
                      {actionLink.includes(';') ? (
                        <FilesIcons
                          filesType={actionLink
                            ?.split(';')
                            ?.filter(link => link !== '')
                            ?.map(link => link.split('.')?.pop())}
                          max={2}
                          onClick={e => {
                            action.link && handleFocusLinkInput(e, action, 'table');
                          }}
                        />
                      ) : (
                        <div>
                          {getItemIcon(actionLink?.split('.')?.pop(), e => {
                            action.link && handleFocusLinkInput(e, action, 'table');
                          })}
                        </div>
                      )}

                      {action.link && (
                        <div ref={deleteDocumentRef}>
                          <RiDeleteBin2Line
                            size={21}
                            className={'erase'}
                            color='red'
                            onClick={e => {
                              e.stopPropagation();
                              handleCheckedActions(
                                {
                                  target: {
                                    checked: true,
                                    value: '',
                                  },
                                },
                                action,
                                'link',
                                action.isEntityScoop
                              );
                            }}
                          />
                          <Tippy content='Supprimer les docs' reference={deleteDocumentRef} placement='bottom' />
                        </div>
                      )}
                    </>
                  )}
                </LinkField>
                {/* Contentful */}
                {displayDebug && (
                  <SeeOnContentful target={'_blank'} href={getEntryUrl(action.id)} onClick={e => e.stopPropagation()}>
                    See on contentful
                  </SeeOnContentful>
                )}
              </CustomStack>
            );
          },
          proofCol: () => {
            return (
              <CustomStack direction='row' spacing={2}>
                {/* Proof */}
                {action.proof && action.proof.required && (action.proof.link || action.proof.documents) && <PiListMagnifyingGlassDuotone size={25} />}
                {/* Discussion */}
                <Container ref={discussionRef} pointer>
                  {isMessageForLegalOfficerUnread(action) ? (
                    <Infos>
                      <ChatBubbleIcon
                        active={Array.isArray(action.comment) && action.comment.length > 0}
                        onClick={e => {
                          e.stopPropagation();
                          handleShowActionDetail(action, 'chat');
                        }}
                      />
                    </Infos>
                  ) : (
                    <ChatBubbleIcon
                      active={Array.isArray(action.comment) && action.comment.length > 0}
                      onClick={e => {
                        e.stopPropagation();
                        handleShowActionDetail(action, 'chat');
                      }}
                    />
                  )}
                  <Tippy content='Discussion' reference={discussionRef} placement='bottom' />
                </Container>
              </CustomStack>
            );
          },
        };
      });
    }
    return [];
  }, [selectedEntity, adminData, displayDebug]);

  const filtersCount = useMemo(() => {
    return Object.keys(filters).reduce((acc, key) => {
      const filterArray = filters[key];
      if (Array.isArray(filterArray) && filterArray.length > 0) {
        acc += filterArray.length;
      }
      return acc;
    }, 0);
  }, [filters]);

  const getEntitiesOptions = () => {
    return selectedAccount?.entities?.map(entity => ({
      label: entity.name,
      value: entity,
    }));
  };

  useEffect(() => {
    if (getEntitiesOptions().length === 1) {
      setLoading(true);
      setSelectedEntity(getEntitiesOptions()[0].value);
    }
  }, []);

  const getProductFilterItems = () => {
    const products = getProductsOptions();
    products.push({
      label: selectedEntity?.name,
      value: selectedEntity?._id,
    });
    return products;
  };

  const filtersMenuItems = [
    {
      targetFilter: 'product',
      placeholder: t('common.product'),
      value: filters.product,
      options: getProductFilterItems(),
    },
    {
      targetFilter: 'urgency',
      placeholder: t('common.urgency'),
      value: filters.urgency,
      options: urgencyComplexity,
    },
    {
      targetFilter: 'status',
      placeholder: t('common.status'),
      value: filters.status,
      options: status,
    },
    {
      targetFilter: 'category',
      placeholder: t('common.category'),
      value: filters.category,
      options: category,
    },
    {
      targetFilter: 'complexity',
      placeholder: t('common.complexity'),
      value: filters.complexity,
      options: urgencyComplexity,
    },
  ];

  const getSelectedEntity = () => {
    if (selectedEntity) {
      return { label: selectedEntity.name, value: selectedEntity };
    } else if (getEntitiesOptions().length === 1) {
      return getEntitiesOptions()[0];
    }
    return null;
  };

  const handleDownloadActionPlan = async () => {
    dispatch(setLoading(true));
    const { actionPlanBuffer } = await downloadActionPlanBuffer(selectedAccount._id, selectedEntity._id);
    let blob = new Blob([new Uint8Array(actionPlanBuffer.data)], { type: 'application/octet-stream' });
    dispatch(setLoading(false));
    saveAs(
      blob,
      new Date().toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }) +
        '_ACTION_PLAN_' +
        selectedEntity.name +
        '.xlsx'
    );
  };

  const handleOpenFullContentModal = (title: string, value: string, field: string) => {
    setFullContentModalTitle(title);
    setFullContentModalValue(value);
    setFullContentModalField(field);
    setShowFullContentModal(true);
  };

  const handleFullContentModalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFullContentModalValue(value);

    if (fullContentModalField === 'description') {
      setDescription(value);
    } else if (fullContentModalField === 'explanation') {
      setExplanation(value);
    } else if (fullContentModalField === 'precision') {
      setPrecision(value);
    }

    setCustomAction(prevState => ({
      ...prevState,
      [fullContentModalField]: value,
    }));
  };

  const modalTitle = (() => {
    switch (true) {
      case addActionFromDirectory:
        return t('actionPlan.modal.edit_and_add_custom_action_title');
      case editAction:
        return t('actionPlan.modal.edit_custom_action_title');
      case editDirectoryAction:
        return t('actionPlan.modal.edit_directory_action');
      case duplicateAction:
        return t('actionPlan.modal.duplicate_action');
      default:
        return t('actionPlan.modal.create_custom_action_title');
    }
  })();

  const modaleValidateLabel = (() => {
    switch (true) {
      case addActionFromDirectory:
        return t('actionPlan.modal.transfer_to_prepared_actions');
      case editDirectoryAction:
        return t('actionPlan.modal.edit_action');
      default:
        return t('common.validate');
    }
  })();

  return (
    <PageWrapper selectedView={view} showPageTitle={false}>
      <Tabs id='page-tabs' views={views} selectedView={view.value} tabsUnderlined />
      <Container flex justifyContent='flex-end' position='absolute' right='2rem'>
        {selectedEntity && view.value === 'adminActions' && (
          <ActionContainer>
            <Button onClick={handleDownloadActionPlan} label={"Télécharger le plan d'action"} />
          </ActionContainer>
        )}
      </Container>
      {/* Admin Action Data */}
      <DataTable
        loading={isDataLoading}
        rows={adminRows}
        columns={filteredAdminColumns}
        rowActions={adminRowAction}
        onRowClick={action => handleShowActionDetail(action, 'detail')}
        checkboxSelection
        allItemsSelection={selectAllActions}
        setAllItemsSelection={setSelectAllActions}
        checkRowSelection={getCheckedActions}
        handleRowSelection={handleSelectActions}
        searchPlaceholder={t('actionPlan.search_actions')}
        searchQuery={query}
        setSearchQuery={setQuery}
        onFilterClick={handleFiltersClick}
        onFilterReset={handleFiltersReset}
        filtersCount={filtersCount}
        noDataText={noTableDataAvailable(selectedEntity, view.value)}
        disableFilters={!selectedEntity}
        disableSearchBar={!selectedEntity}
        headerComponents={
          <ActionPlanHeader
            getEntitiesOptions={getEntitiesOptions}
            getSelectedEntity={getSelectedEntity}
            setSelectedEntity={setSelectedEntity}
            query={query}
            setQuery={setQuery}
            handleFiltersClick={handleFiltersClick}
            handleFiltersReset={handleFiltersReset}
            filtersCount={filtersCount}
            selectedEntity={selectedEntity}
            view={view}
            setShowCustomActionModal={setShowCustomActionModal}
            expandedCustom={expandedCustom}
            actionDirectory={
              <ActionDirectory
                rows={customActionsData}
                setShowCustomActionModal={setShowCustomActionModal}
                setAddActionFromDirectory={setAddActionFromDirectory}
                setCustomAction={setCustomAction}
                setDescription={setDescription}
                setExplanation={setExplanation}
                setPrecision={setPrecision}
                activeAccount={activeAccount}
                handleEditAction={handleEditAction}
                handleDeleteCustomAction={handleDeleteCustomAction}
                deleteActionInDirectory={deleteActionInDirectory}
                setDeleteActionInDirectory={setDeleteActionInDirectory}
                expanded={expandedCustom}
                setExpanded={setExpandedCustom}
              />
            }
          />
        }
        hideTable={expandedCustom && view.value === 'backlog'}
      />
      {/* Action details Drawer */}
      <ActionDetails
        selectedAccount={selectedAccount}
        impersonated={impersonated}
        isAnyNewComment={isAnyNewComment}
        selectedAction={selectedAction}
        activeAccount={activeAccount}
        setSelectedAction={setSelectedAction}
        selectedEntity={selectedEntity}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        selectedDrawerItemID={selectedDrawerItemID}
        currentUser={currentUser}
        admin={true}
        editedAction={editedAction}
        setShowSubcontractorInputModal={null}
      />

      {/* Custom Action Creation Modal */}
      {showCustomActionModal && (
        <Modal
          show={showCustomActionModal}
          title={modalTitle}
          Icon={editAction || editDirectoryAction || addActionFromDirectory ? GoPencil : duplicateAction ? HiOutlineDuplicate : null}
          iconColor={editDirectoryAction && COLORS.lightViolet}
          onClose={handleCustomActionModalClose}
          styleTitle={{ color: COLORS.Black, fontWeight: '600' }}>
          <ModalContent>
            {!editAction && !addActionFromDirectory && !editDirectoryAction && !duplicateAction && (
              <FormControlLabel
                style={{ alignSelf: 'start', marginTop: '-25px' }}
                name='addDirectory'
                label={<CheckCustomActionDirectory>{t('actionPlan.add_to_directory')}</CheckCustomActionDirectory>}
                control={<Checkbox />}
                onChange={handleCustomDataChange}
              />
            )}
            <TextField
              label={t('common.title')}
              variant='outlined'
              name='title'
              type='search'
              fullWidth
              defaultValue={customAction?.title}
              onChange={handleCustomDataChange}
              required
            />
            {editAction || addActionFromDirectory || duplicateAction ? (
              <AdjustActions>
                <TextField
                  label={t('common.product_entity')}
                  variant='outlined'
                  name='versionProductId'
                  fullWidth
                  select
                  defaultValue={editAction ? customAction?.relatedProductId || customAction?.versionProductId : null}
                  onChange={handleCustomDataChange}
                  required>
                  <MenuItem disabled>{t('common.product')}</MenuItem>
                  {getProductsOptions().map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                  <MenuItem disabled>{t('common.entity')}</MenuItem>
                  <MenuItem key={'entity'} value={selectedEntity._id}>
                    {selectedEntity.name}
                  </MenuItem>
                </TextField>
                <TextField
                  label={t('common.category')}
                  variant='outlined'
                  name='category'
                  type='search'
                  select
                  fullWidth
                  defaultValue={customAction?.category}
                  onChange={handleCustomDataChange}
                  required>
                  {category
                    .filter(categoryItem => categoryItem.value !== 'Audit')
                    .map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </AdjustActions>
            ) : (
              <>
                {!editDirectoryAction && (
                  <TextField
                    label={t('common.product_entity')}
                    variant='outlined'
                    name='versionProductId'
                    fullWidth
                    select
                    defaultValue={editAction ? customAction?.relatedProductId || customAction?.versionProductId : null}
                    onChange={handleCustomDataChange}
                    required>
                    <MenuItem disabled>{t('common.product')}</MenuItem>
                    {getProductsOptions().map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem disabled>{t('common.entity')}</MenuItem>
                    <MenuItem key={'entity'} value={selectedEntity._id}>
                      {selectedEntity.name}
                    </MenuItem>
                  </TextField>
                )}

                <AdjustActions>
                  <TextField
                    label={t('common.category')}
                    variant='outlined'
                    name='category'
                    type='search'
                    select
                    fullWidth
                    defaultValue={customAction?.category}
                    onChange={handleCustomDataChange}
                    required>
                    {category
                      .filter(categoryItem => categoryItem.value !== 'Audit')
                      .map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    label={t('common.subcategory')}
                    variant='outlined'
                    name='subCategory'
                    type='search'
                    select
                    fullWidth
                    defaultValue={customAction?.subCategory}
                    onChange={handleCustomDataChange}
                    required>
                    {subCategory.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </AdjustActions>
              </>
            )}

            <AdjustActions>
              <TextField
                label={t('common.urgency')}
                variant='outlined'
                name='urgency'
                select
                fullWidth
                defaultValue={customAction?.urgency}
                onChange={handleCustomDataChange}
                required>
                {urgencyComplexity.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label={t('common.complexity')}
                variant='outlined'
                name='complexity'
                select
                fullWidth
                defaultValue={customAction?.complexity}
                onChange={handleCustomDataChange}
                required>
                {urgencyComplexity.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </AdjustActions>

            {!editDirectoryAction && (
              <TextField
                // defaultValue={''}
                value={getFileNameFromKey(customAction?.link) ?? ''}
                label={t('common.document_link')}
                variant='outlined'
                name='link'
                type='search'
                fullWidth
                onFocus={e => handleFocusLinkInput(e, customAction)}
              />
            )}

            <TextField
              label={t('common.description')}
              variant='outlined'
              name='description'
              multiline
              rows={3}
              fullWidth
              defaultValue={customAction?.description}
              onChange={handleCustomDataChange}
              required
              value={description}
              InputProps={{
                endAdornment: (
                  <ExpandFieldIcon size={12} onClick={() => handleOpenFullContentModal(t('common.description'), description, 'description')} />
                ),
              }}
            />

            <TextField
              label={t('actionPlan.modal.integration_explanation')}
              variant='outlined'
              name='explanation'
              multiline
              rows={2}
              fullWidth
              defaultValue={customAction?.explanation}
              onChange={handleCustomDataChange}
              value={explanation}
              InputProps={{
                endAdornment: (
                  <ExpandFieldIcon
                    size={12}
                    onClick={() => handleOpenFullContentModal(t('actionPlan.modal.integration_explanation'), explanation, 'explanation')}
                  />
                ),
              }}
            />
            <TextField
              label={t('common.precision')}
              variant='outlined'
              name='precision'
              multiline
              rows={2}
              fullWidth
              defaultValue={customAction?.precision}
              onChange={handleCustomDataChange}
              value={precision}
              InputProps={{
                endAdornment: <ExpandFieldIcon size={12} onClick={() => handleOpenFullContentModal(t('common.precision'), precision, 'precision')} />,
              }}
            />
          </ModalContent>
          <ButtonsContainer>
            <Button type='text' onClick={handleCustomActionModalClose} label={t('common.cancel')} />
            <Button
              type='primary'
              disabled={!editAction && !editDirectoryAction && !customAction?.isReady}
              onClick={
                editAction ? () => handleEditCustomAction(false) : editDirectoryAction ? () => handleEditCustomAction(true) : handleCreateCustomAction
              }
              label={modaleValidateLabel}
            />
          </ButtonsContainer>
          {showFullContentModal && (
            <Modal
              size={{ width: '550px' }}
              show={showFullContentModal}
              title={`${fullContentModalTitle} :`}
              styleTitle={{ color: COLORS.darkGrey, fontWeight: '400' }}
              top={'25%'}
              onClose={() => setShowFullContentModal(false)}>
              <TextField
                label={fullContentModalTitle}
                variant='outlined'
                name={fullContentModalField}
                value={fullContentModalValue}
                multiline
                rows={20}
                fullWidth
                onChange={handleFullContentModalChange}
              />
            </Modal>
          )}
        </Modal>
      )}

      {showAvailableDocuments?.show && (
        <Modal
          size={{ height: '100%' }}
          show={showAvailableDocuments?.show}
          title={t('actionPlan.available_documents')}
          onClose={() => setShowAvailableDocuments({ action: undefined, show: false, context: undefined })}>
          <FileExplorer
            embedded
            selectedEntity={selectedEntity}
            customClick={handleFileChoice}
            selectedFileKey={showAvailableDocuments?.action?.link}
            admin
          />
        </Modal>
      )}
      {checkedActions.length > 0 && (
        <FloatingActionBar>
          <CheckedElements>{checkedActions.length} action(s) sélectionné(s)</CheckedElements>
          <Container flex mrChild='1rem'>
            {view.value === 'backlog' ? (
              <ActionContainer>
                <Button
                  type='primary'
                  onClick={() => handleUpdateAuthorizedActions('upgrade')}
                  label={t('actionPlan.transfer_to_prepared_actions')}
                  icon={VscDebugStepOver}
                />
              </ActionContainer>
            ) : view.value === 'actionsPrepared' ? (
              <>
                <ActionContainer>
                  <Button type='text' onClick={() => handleUpdateAuthorizedActions('downgrade')} label={t('common.downgrade')} />
                </ActionContainer>
                <ActionContainer>
                  <Button type='primary' onClick={() => handleUpdateAuthorizedActions('upgrade')} label={t('common.activate')} />
                </ActionContainer>
              </>
            ) : (
              <ActionContainer>
                <Button type='text' onClick={() => handleUpdateAuthorizedActions('downgrade')} label={t('common.downgrade')} />
              </ActionContainer>
            )}
          </Container>
        </FloatingActionBar>
      )}

      {deleteAction && (
        <Modal
          show={deleteAction ? true : false}
          title={t('actionPlan.modal.delete_custom_action_title', { name: deleteAction.title })}
          onClose={() => setDeleteAction(null)}
          Icon={RiDeleteBinLine}
          iconColor={'#FF746F'}>
          <ConfirmContainer>
            <div>
              {t('actionPlan.modal.confirmation_delete_action')} <span>“{deleteAction.title}“</span> ?
            </div>
            <ButtonsContainer>
              <Button type='text' onClick={() => setDeleteAction(null)} label={t('common.cancel')} />
              <Button type='primary' onClick={() => handleDeleteCustomAction(false)} label={t('common.delete')} />
            </ButtonsContainer>
          </ConfirmContainer>
        </Modal>
      )}

      <FiltersMenu
        filtersMenAnchorEl={filtersMenAnchorEl}
        setFiltersMenAnchorEl={setFiltersMenAnchorEl}
        items={filtersMenuItems}
        handleFilterChange={handleFilterChange}
      />
    </PageWrapper>
  );
};

export default AdminActionPlan;

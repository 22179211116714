import styled from 'styled-components';

export const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;
  width: 100%;

  p {
    font-size: 1.3em;
    line-height: 1.5em;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
}
`;

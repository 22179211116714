import { COLORSV2 } from '@utils/constants';
import styled from 'styled-components';

export const AdminUserListContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const UserListBody = styled.div<{ admin: boolean }>`
  display: grid;
  grid-template-columns: ${({ admin }) => (admin ? '1fr 4fr 4fr 5fr 2fr 2fr 2fr' : '1fr 4fr 4fr 5fr 4fr 2fr')};
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
  overflow: auto;

  span {
    font-size: 1.125em;
    font-weight: bold;
  }
`;

export const UserListHeader = styled.div<{ admin: boolean }>`
  display: grid;
  grid-template-columns: ${({ admin }) => (admin ? '1fr 4fr 4fr 5fr 2fr 2fr 2fr' : '1fr 4fr 4fr 5fr 4fr 2fr')};
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;

  span {
    font-size: 1.125em;
    font-weight: bold;
    color: ${COLORSV2.primary};
  }
`;

export const UserListContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: justify-content;
  row-gap: 20px;
  font-size: 1.5em;
  padding: 20px;
  background-color: red;

  span {
    font-weight: 600;
    color: ${COLORSV2.primary};
  }
`;

export const UserListDataContent = styled.div`
  display: contents;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 20px;
  font-size: 1.5em;
`;

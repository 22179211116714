import { COLORSV2 } from '@utils/constants';
import styled from 'styled-components';

export const UserAdministrationContainer = styled.div`
  display: flex;
  padding-top: 35px;
  width: calc(100% - 50px);
  height: calc(100% - 40px);
  overflow-y: auto;
  flex-direction: column;
`;

export const Subtitle = styled.div`
  font-size: 1.3em;
  padding-top: 35px;
  padding-bottom: 20px;
  color: ${COLORSV2.lightOrange};
  font-family: Nexa Bold;
  font-weight: 700;
`;

export const PageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

import styled from 'styled-components';
import { COLORS } from '@utils/constants';

export const EntityContainer = styled.div``;

export const EntitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
`;

export const EntityTitle = styled.div``;

export const DocumentsList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`;

export const Tab = styled.div<{ current: boolean }>`
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  ${({ current }) =>
    current &&
    `
    border-bottom: 1px solid #187ead;
  `};
`;

export const DashboardGenerationContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div``;

export const VersionChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 20px;
  column-gap: 20px;
  overflow-y: auto;
  margin-top: 20px;
  padding: 20px 20px 60px 20px;

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
  }
`;

export const FixInputContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  margin-top: 15px;

  column-gap: 15px;

  > * {
    flex: 1;
  }
`;

export const VersionItem = styled.div``;

export const VersionItemWarning = styled.div`
  color: ${COLORS.softRed};
  font-weight: 400;
  margin-top: 10px;
`;

export const ActionContainer = styled.div`
  display: flex;
  column-gap: 15px;
  justify-content: flex-end;
  padding: 0 100px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  align-items: center;
  min-height: 70px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.label`
    cursor: pointer;
`;

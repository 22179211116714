const finalities = [
  'A/B testing',
  'Accueil du public',
  'Bandeau cookies',
  'Cabinet comptable',
  'Chat/chatbot',
  'Collecte de dons',
  'Communication réseaux sociaux',
  'Cookies publicitaires',
  'Cookies statistiques exonérés de consentement',
  'Cookies statistiques non exonérés de consentement',
  'CRM (Customer Relationship Management)',
  "DSI externalisé (Direction des Systèmes d'Information)",
  'Enrichissement de CRM',
  'Enregistrement téléphonique',
  'Enquête de satisfaction',
  'Fonds de commerce',
  'Formulaire de contact',
  'Fourniture, réparation et gestion des stocks des équipements informatiques',
  'Gestionnaire de mots de passe',
  'Gestion de la data room',
  'Gestion de la paie',
  'Gestion des élections professionnelles',
  'Gestion des impayés',
  'Gestion des jeux concours',
  'Gestion des livraisons',
  'Gestion des notes de frais',
  'Gestion des publicités en ligne',
  'Gestion des reçus par mail',
  'Gestion des recrutements',
  'Gestion des véhicules de fonction',
  'Gestion du CSE (Comité Social et Économique)',
  'Gestion du service client',
  'Gestion du temps de travail et des absences',
  'Hébergement de base de données',
  'Hébergement de solution',
  'KYC (Know Your Customer)',
  'Lecteur vidéo',
  'Livraison',
  'Messagerie',
  'Messagerie électronique',
  'Newsletter',
  'Notification technique par email',
  'Notification technique par SMS',
  "Outil d'enquête de satisfaction",
  "Outil d'échange sécurisé de documents",
  "Outil d'intelligence artificielle",
  "Outil d'éligibilité",
  'Outil de bureautique',
  'Outil de click and collect',
  'Outil de création de compte',
  'Outil de développement',
  'Outil de facturation',
  'Outil de formation',
  'Outil de gestion de projet',
  'Outil de gestion des formations',
  'Outil de gestion des livraisons',
  'Outil de gestion des publicités en ligne',
  'Outil de gestion du service client',
  'Outil de gestion programme de fidélité',
  'Outil de gestion SAV',
  'Outil de KYC',
  'Outil de newsletter',
  'Outil de paiement',
  'Outil de prospection par mail',
  'Outil de prospection par SMS',
  'Outil de prospection par téléphone',
  'Outil de prospection par voie postale',
  "Outil de réalisation d'audits",
  'Outil de réservation',
  'Outil de scoring',
  'Outil de visioconférence',
  'Paiement',
  'Paiement différé/échelonné',
  'Paiement en ligne',
  'Prospection par mail',
  'Prospection par SMS',
  'Prospection par téléphone',
  'Prospection par voie postale',
  'Réseaux sociaux',
  'Sécurité des locaux',
  'Serveur CDN (Content Delivery Network)',
  'Serveur de secours',
  'Signature électronique',
  'Site de recrutement',
  "Système de protection des lanceurs d'alerte",
  'Scoring',
  "Test d'éligibilité",
  'Vidéoprotection',
];

const newSubContractorInitialState = {
  contactEmail: '',
  finality: null,
  subContractorName: '',
  status: '',
  relatedSurveyId: null,
};

export { finalities, newSubContractorInitialState };

import { COLORSV2 } from '@utils/constants';
import styled from 'styled-components';

export const Aside = styled.aside`
  width: 250px;
  padding-left: 20px;
  height: 100vh;
  border-radius: 0px 10px 10px 0px;
  background: ${COLORSV2.lightPink};
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.08);
`;

export const SidebarLink = styled.a<{ active: boolean }>`
  font-size: 14px;
  font-family: 'Biennale';
  font-weight: 600;
  color: ${COLORSV2.primary};
  gap: 15px;
  display: flex;
  align-items: center;
  padding: 12px;
  padding-left: 30px;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;

  &:hover {
    color: ${COLORSV2.primary};
    outline: none;
    position: relative;
    background-color: ${COLORSV2.deepPink};

    transition: 0.2s;
  }

  i {
    margin-right: 5px;
  }

  svg {
    width: 25px;
    height: 25px;
  }

  ${({ active }) =>
    active &&
    `
    color: ${COLORSV2.primary};
    outline: none;
    position: relative;
    background-color: ${COLORSV2.deepPink};
`}
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 40px 0;
  font-size: 20px;
  background: linear-gradient(326deg, ${COLORSV2.lightOrange} 55.23%, ${COLORSV2.pink} 85.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`;

export const SideBarItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

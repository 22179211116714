import { DataTable } from '@components-new/organisms';
import { useState } from 'react';
import { BsDashCircle, BsPlusCircle } from 'react-icons/bs';
import { Chip, Container, MoreOptions } from '@components-new/atoms';
import { actionsUrgencyComplexity } from '@components-new/common/constants';
import { t } from 'i18next';
import { dataTableColumnsProps } from '@components-new/common/types';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { ButtonsContainer, ConfirmContainer, CustomActionsChoose, CustomActionsContainer, StyledInputComponent } from '../ActionPlan_.style';
import { ActionProps } from '../ActionPlan.types';
import { category, subCategory } from '../ActionPlan.constants';
import { getLabelByValue } from '../ActionPlan.utils';
import { GoArrowRight, GoPencil } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';
import { COLORS, LEGAL_OPS } from '@utils/constants';
import { Button } from '@components/Button';
import { Modal } from '@components/modal/Modal';

const ActionDirectory = ({
  rows,
  setShowCustomActionModal,
  setAddActionFromDirectory,
  setCustomAction,
  setDescription,
  setExplanation,
  setPrecision,
  activeAccount,
  handleEditAction,
  handleDeleteCustomAction,
  deleteActionInDirectory,
  setDeleteActionInDirectory,
  expanded,
  setExpanded,
}) => {
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>(null);

  let actions = rows.map(action => {
    return {
      ...action,
      titleCol: action?.title,
      urgencyCol: () => {
        const { label, color, backgroundColor } = actionsUrgencyComplexity[action?.urgency];
        return <Chip variant='outlined' label={t(label)} color={color} backgroundColor={backgroundColor} />;
      },
      complexityCol: () => {
        const { label, color, backgroundColor } = actionsUrgencyComplexity[action?.complexity];
        return <Chip variant='outlined' label={t(label)} color={color} backgroundColor={backgroundColor} />;
      },
      categoryCol: () => {
        return <Chip label={getLabelByValue(category, action?.category)} />;
      },
      subCategoryCol: () => {
        return (
          <>
            <Chip icon={GoArrowRight} iconSize={20} label={getLabelByValue(subCategory, action?.subCategory)} />
          </>
        );
      },
      chooseCol: () => {
        return (
          <Container flex mrChild='10px' alignItems='center'>
            <CustomActionsChoose>
              <BsPlusCircle size='16' />
              {t('common.choose')}
            </CustomActionsChoose>
          </Container>
        );
      },
      actionCol: () => {
        return LEGAL_OPS.includes(activeAccount._id) && <MoreOptions svgColor={COLORS.lightViolet} options={rowActions} argOnclick={action} />;
      },
      usageCol: () => {
        return <span>{action.usages.length}</span>;
      },
    };
  });

  const filteredDataCustoms = data => {
    return actions.filter((item: any) => {
      // Apply Sub Category filter
      if (selectedSubCategory && selectedSubCategory.value !== item?.subCategory) {
        return false;
      }
      return true;
    });
  };
  const adminColumns: dataTableColumnsProps[] = [
    {
      field: 'titleCol',
      headerName: t('common.action'),
      width: 'col-2',
      bolder: true,
      center: false,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'urgencyCol',
      headerName: t('common.criticality'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'complexityCol',
      headerName: t('common.complexity'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'categoryCol',
      headerName: t('common.category'),
      width: 'col-1-5',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'subCategoryCol',
      headerName: t('common.subcategory'),
      width: 'col-1-5',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'usageCol',
      headerName: t('common.usages'),
      width: 'col-1',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: '',
      headerName: '',
      width: 'col-2',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    {
      field: 'chooseCol',
      width: 'col-0-5',
      headerName: '',
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
    LEGAL_OPS.includes(activeAccount._id) && {
      field: 'actionCol',
      width: 'col-0-5',
      headerName: 'Lops',
      style: { color: COLORS.lightViolet },
      bolder: true,
      center: true,
      placeholder: { variant: 'line', width: 80 },
    },
  ];

  const handleOpenChooseCustom = (action: ActionProps) => {
    const { title, description, complexity, urgency, category, subCategory, explanation, precision, directoryActionId } = action;
    setAddActionFromDirectory(true);
    setDescription(description);
    setExplanation(explanation);
    setPrecision(precision);
    setCustomAction({
      title,
      description,
      complexity,
      urgency,
      category,
      subCategory,
      precision,
      explanation,
      custom: true,
      isReady: false,
      directoryActionId,
      draft: true,
    });
    setShowCustomActionModal(true);
  };

  const rowActions = [
    {
      icon: GoPencil,
      iconColor: COLORS.lightViolet,
      label: t('common.edit'),
      onClick: row => {
        handleEditAction(row, true);
      },
      showInMenu: true,
    },
    {
      icon: RiDeleteBinLine,
      iconColor: COLORS.lightViolet,
      label: t('common.delete'),
      onClick: row => {
        setDeleteActionInDirectory(row);
      },
      showInMenu: true,
    },
  ];

  return (
    <CustomActionsContainer expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        sx={{
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent',
            outline: 'none',
          },
          '&.Mui-expanded': {
            minHeight: '0',
            height: '50px',
          },
        }}
        aria-controls='panel1-content'
        id='panel1-header'>
        <Container flex justifyContent='space-between' alignItems='center' width='100%'>
          <Container flex alignItems='center'>
            {expanded ? <BsDashCircle size={16} style={{ marginRight: 8 }} /> : <BsPlusCircle size={16} style={{ marginRight: 8 }} />}
            <span style={{ fontSize: '14px', color: COLORS.Black }}>
              {expanded ? t('actionPlan.close_customs_actions') : t('actionPlan.open_customs_actions')}{' '}
            </span>
          </Container>
          {expanded && (
            <Container width='22rem'>
              <StyledInputComponent
                placeholder={t('common.subcategory')}
                type={'select'}
                options={subCategory}
                onChange={(_value: string, _option: any, _context: any, selectedOption: { label: string; value: string }) => {
                  setSelectedSubCategory(selectedOption);
                }}
                value={selectedSubCategory}
                sharable={false}
                noOptionsMessage={() => t('inventory.no_subCategory_available')}
                roundedBorder='17px'
                fontFamily='Nunito'
                clearable='true'
                inputFontSize='1.2rem'
              />
            </Container>
          )}
        </Container>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          height: '55vh',
        }}>
        <DataTable
          loading={false}
          columns={adminColumns}
          rows={filteredDataCustoms(actions)}
          disableFilters={true}
          disableSearchBar={true}
          noDataText={t('actionPlan.no_actions_available')}
          onRowClick={row => handleOpenChooseCustom(row)}
          tableCustom={true}
        />
      </AccordionDetails>

      {deleteActionInDirectory && (
        <Modal
          show={deleteActionInDirectory ? true : false}
          title={t('actionPlan.modal.delete_directory_action_title')}
          onClose={() => setDeleteActionInDirectory(null)}
          Icon={RiDeleteBinLine}
          iconColor={COLORS.lightViolet}
          styleTitle={{ fontWeight: '600' }}>
          <ConfirmContainer>
            <div>
              {t('actionPlan.modal.confirmation_delete_action')} <span>“{deleteActionInDirectory.title}“</span> ?
            </div>
            <ButtonsContainer>
              <Button onClick={() => setDeleteActionInDirectory(null)} label={t('common.cancel')} type='light' />
              <Button onClick={() => handleDeleteCustomAction(true)} label={t('common.delete')} />
            </ButtonsContainer>
          </ConfirmContainer>
        </Modal>
      )}
    </CustomActionsContainer>
  );
};

export default ActionDirectory;

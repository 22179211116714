import styled, { css } from 'styled-components';

import { Z_INDEX } from '@utils/constants';

export const ChildContainer = styled.div<{ show: boolean }>`
  width: fit-content;
  position: relative;
`;

export const MoreContainer = styled.div<{ show?: boolean; rotate?: boolean; removeBorder?: boolean; svgColor?: string }>`
  position: relative;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding: 5px;
  border: ${({ removeBorder }) => (removeBorder ? 'none' : '2px solid rgba(0, 0, 0, 0)')};
  transition: border 0.2s ease-in-out;

  > svg {
    height: 24px;
    width: 24px;
    color: ${({ svgColor }) => svgColor ?? 'rgba(0, 0, 0, 0.4)'};
    ${({ removeBorder }) =>
      !removeBorder &&
      css`
        transition: all 0.2s ease-in-out;
      `};
    transform: rotate(0deg);
  }

  &:hover {
    cursor: pointer;
    border: ${({ removeBorder }) => (removeBorder ? 'none' : '2px solid rgba(0, 0, 0, 0.5)')};

    > svg {
      color: rgba(0, 0, 0, 0.7);
      ${({ rotate }) => rotate === true && 'transform: rotate(100deg);'}
    }

    ${({ show }) =>
      show &&
      `
        cursor: pointer;
        border: ${({ removeBorder }) => (removeBorder ? 'none' : '2px solid rgba(0, 0, 0, 0.5)')};

        > svg {
          color: rgba(0, 0, 0, 0.7);
        }
      `}
`;

export const PopoverMenu = styled.div<{ show?: boolean; width?: string }>`
  width: ${({ width }) => width ?? '200px'};
  padding: 20px;
  background-color: white;
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 15px;
  transition: opacity 0.2s ease-in-out;
  pointer-events: bounding-box;
  animation: fadeIn 0.2s ease-in-out;
  z-index: ${Z_INDEX.popover};
  border-radius: 10px;
`;

export const MenuItem = styled.div<{ color: string }>`
  font-size: 16px;
  width: 100%;
  font-weight: 500;
  font-family: 'Roboto', serif;
  color: ${({ color }) => color ?? 'black'};
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  align-items: center;
  column-gap: 10px;

  > svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9edf7;
`;

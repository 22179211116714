import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { FiArrowRight } from 'react-icons/fi';
import { Button } from '../Button';
import { InputComponent } from '../form/InputComponent';
import { selectAccount, selectUser, createNewEntity, updateUser } from '../../reducers/account.reducer';
import { paths } from '@routes/routes.constants';
import { WizardSlides } from './WizardSlides';
import { useTranslation } from 'react-i18next';
import {
  SideBar,
  Content,
  Step,
  StepContainer,
  LogoContainer,
  WizardContainer,
  Title,
  Slide,
  ErrorMessage,
  Separator,
  ContentContainer,
} from '@components/wizard/Wizard_.style';
import { Logo } from '@components/logo/logo';

export interface WizardSlide {
  step: number;
  label: string;
  title: string;
  content: any;
  input: boolean;
  inputType: string;
  options: Array<any>;
  buttonLabel?: string;
  conditions: Array<any>;
  children?: any;
}

export interface WizardProps {
  slideList: Array<WizardSlide>;
}

const Wizard = ({ slideList }: WizardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  //REDUX
  const dispatch = useDispatch<any>();
  const account = useSelector(selectAccount);
  const user = useSelector(selectUser);
  //STATE
  const [currentStep, setCurrentStep] = useState(null);
  const [values, setValues] = useState([]);
  const [error, setError] = useState(false);

  const slideListRef = useRef(null);

  const setWizardCompleted = async () => {
    let userCopy = _.cloneDeep(user);
    userCopy.wizardCompleted = true;
    let newEntityName = values.find(value => value.name === 'step-2')?.value;
    if (newEntityName) {
      dispatch(createNewEntity({ accountId: account._id, userId: user._id, name: newEntityName.trim() }));
    }
    dispatch(updateUser({ userId: userCopy._id, data: userCopy }));
    history.push(paths.dashboardGroup);
  };

  const goToStep = async nextStep => {
    let lastStep = nextStep > slideListRef?.current?.length;
    if (lastStep) {
      await setWizardCompleted();
      return;
    } else {
      setCurrentStep(nextStep);
    }
  };

  const isStepConditionsOK = step => {
    if (!step?.conditions?.length) {
      return true;
    }
    let conditions = step.conditions.every(condition => {
      if (typeof condition === 'boolean') {
        return condition;
      }
      return values.find(value => value.name === `step-${condition.step}` && value.value === condition.value);
    });
    return conditions;
  };

  const isSelected = step => {
    return step === currentStep;
  };

  const isStepValid = step => {
    let hasInput = slideListRef?.current[step - 1].input;
    let isEmpty = values.find(value => value.name === `step-${step}`) === undefined;
    let isVisible = isStepConditionsOK(slideListRef?.current?.find(item => item.step === step));
    return !hasInput || !isEmpty || !isVisible;
  };

  const isSelectable = targetStep => {
    if (targetStep === 1 || currentStep > targetStep || !isStepValid(targetStep - 1)) {
      return false;
    }
    return true;
  };

  const handleStepChange = async targetStep => {
    if (isSelectable(targetStep)) {
      setError(false);
      await goToStep(targetStep);
    } else {
      setError(true);
    }
  };

  const handleSetValue = event => {
    let newValues = [...values];
    let stepCopy = newValues.find(value => value.name === event.target.name);
    if (!stepCopy) {
      newValues.push({ name: event.target.name, value: event.target.value });
    } else {
      if (event.target.value === '') {
        newValues = newValues.filter(value => value.name !== event.target.name);
      } else {
        stepCopy.value = event.target.value;
      }
    }
    setValues(newValues);
  };

  const handleSelect = (name, value) => {
    let newValues = [...values];
    let stepCopy = newValues.find(value => value.name === name);
    if (!stepCopy) {
      newValues.push({ name, value });
    } else {
      if (value === '') {
        newValues = newValues.filter(value => value.name !== name);
      } else {
        stepCopy.value = value;
      }
    }
    setValues(newValues);
  };

  const getNextStep = () => {
    for (let step = currentStep; step < slideListRef?.current?.length; step++) {
      if (isStepConditionsOK(slideListRef?.current[step])) return step + 1;
    }
    return currentStep + 1;
  };

  const getStepValue = step => {
    //TODO check default value for select input type
    return values.find(value => value.name === `step-${step}`)?.value;
  };

  const getChildrenProps = () => ({
    isCalendarOpen: true,
    setIsCalendarOpen: () => {},
    activeAccount: account,
    fullPage: true,
    callbackFn: () => handleStepChange(getNextStep()),
  });

  const getFirstStep = () => {
    return slideListRef.current.filter(slide => isStepConditionsOK(slide))[0]?.step;
  };

  useEffect(() => {
    slideListRef.current = WizardSlides(account, t);
    setCurrentStep(getFirstStep());
  }, []);

  return (
    <WizardContainer>
      <SideBar>
        <LogoContainer>
          <Logo text={true} />
          <Separator />
        </LogoContainer>

        <StepContainer>
          {slideListRef?.current?.map((item, idx) => {
            return (
              isStepConditionsOK(item) && (
                <Step key={idx} selected={isSelected(item.step)} isSelectable={currentStep === 1 ? false : isSelectable(item.step)}>
                  <FiArrowRight />
                  {item.label}
                </Step>
              )
            );
          })}
        </StepContainer>
      </SideBar>
      <ContentContainer>
        {slideListRef?.current?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {isStepConditionsOK(item) && item.step === currentStep && (
                <Slide>
                  {item.children ? (
                    <item.children {...getChildrenProps()}></item.children>
                  ) : (
                    <>
                      <Title>{item.title}</Title>
                      <Content>{item.content}</Content>
                      {item.input && (
                        <InputComponent
                          name={`step-${item.step}`}
                          type={item.inputType}
                          sharable={false}
                          value={getStepValue(item.step)}
                          onChange={item.inputType === 'select' ? handleSelect : handleSetValue}
                          options={item.options}
                        />
                      )}
                      {item.step === currentStep && error && <ErrorMessage>Réponse obligatoire</ErrorMessage>}
                      {item.buttonLabel && (
                        <Button
                          type='primary'
                          label={item.buttonLabel}
                          onClick={() => handleStepChange(getNextStep())}
                          disabled={!isStepValid(item.step)}
                        />
                      )}
                    </>
                  )}
                </Slide>
              )}
            </React.Fragment>
          );
        })}
      </ContentContainer>
    </WizardContainer>
  );
};

export default Wizard;

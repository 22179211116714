import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAllContentfulData } from '../reducers/contentful.reducer';
import { selectSelectedAccount } from '../reducers/account.reducer';

export const useDocxOptions = (lang) => {
  const contentfulData = useSelector(selectAllContentfulData);
  const selectedAccount = useSelector(selectSelectedAccount);

  return useMemo(() => {
    if (!contentfulData?.documents || !selectedAccount?.actionRights?.documents) return [];
    const allowedDocumentsIds = selectedAccount.actionRights.documents.map(doc => doc.id);

    return contentfulData.documents
      .filter(document => allowedDocumentsIds.includes(document.id) && document.lang === lang)
      .map(document => ({ value: document.id, label: document.name }));

  }, [contentfulData, selectedAccount, lang]);
};

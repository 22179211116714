import { saveAs } from 'file-saver';

import { Status } from './AuditsAndInventory.types';
import { getAuditQuestionInfosByName } from '@utils/utils';

export const sortSubcontractorsByStatus = data => {
  const statusOrder: Record<Status, number> = {
    conforme: 0,
    ongoing: 1,
    non_conforme: 2,
  };

  return [...data].sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
};

export const formatDate = () => {
  return new Date().toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const createAndSaveInventoryBlob = (buffer: ArrayBuffer, fileName: string) => {
  const blob = new Blob([new Uint8Array(buffer)], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}${formatDate()}.xlsx`);
};

export const formatSubcontractorQuestionAndResponses = (key, value, surveyData) => {
  const questionInfo = getAuditQuestionInfosByName(surveyData, key);
  const questionLabel = questionInfo.label;
  if (Array.isArray(value)) {
    return { questionLabel, response: value.map(elt => questionInfo.options?.find(item => item.value === elt)?.label).join(', ') };
  }
  const response = questionInfo.options ? questionInfo.options?.find(item => item.value === value || value.includes(item.value))?.label : value;
  return { questionLabel, response };
};

export const getFinalityOptions = finalities => {
  return finalities?.map((finality: string) => ({
    label: finality,
    value: finality,
  }));
};

export const getEntitiesOptions = account => {
  return account?.entities?.map(entity => ({
    label: entity.name,
    value: entity,
  }));
};

import styled from 'styled-components';

import { COLORS } from '../../utils/constants';

export interface BreadcrumbProps {
  items?: Array<any>;
}

export const Breadcrumb = ({ items }: BreadcrumbProps) => {
  const getBreadcrumbElements = BCItems => {
    let elements = [];
    BCItems?.forEach((item, index) => {
      elements.push(
        <BreadcrumbItem key={`item-${index}`} {...item}>
          {item.name?.replace('_', ' ')}
        </BreadcrumbItem>
      );
      if (index !== BCItems.length - 1) {
        elements.push(<p key={`sep-${index}`}>{'>'}</p>);
      }
    });
    return elements;
  };

  return <BreadcrumbContainer>{getBreadcrumbElements(items)}</BreadcrumbContainer>;
};

const BreadcrumbContainer = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 10px;
  font-family: Nexa;
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.lightGrey};
`;

const BreadcrumbItem = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  &:first-of-type {
    margin-left: -5px;
  }

  &:last-of-type {
    font-family: 'Nexa Bold';
    font-weight: 700;
    color: ${COLORS.Black};
    cursor: auto;
    text-decoration: auto;
  }
`;

import { Button } from '@components/Button';
import { Modal } from '@components/modal/Modal';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonContainer, NextButton, PreviousButton } from './WizardModale_.style';
import { BUTTON_COLORS } from '@utils/constants';
import * as React from 'react';

const WizardModale = ({ show, step, setStep, onClose, steps, title, resetForm }) => {
  const { t } = useTranslation();

  const [isStepValid, setIsStepValid] = useState(true);

  const validateStep = () => {
    const currentStep = steps[step];
    setIsStepValid(currentStep.isValid);
  };

  const nextStep = () => {
    if (isStepValid) {
      const currentStep = steps[step];
      if (currentStep.action) {
        currentStep.action();
      }

      setStep(prevStep => Math.min(prevStep + 1, steps.length - 1));
    }
  };

  const prevStep = () => {
    setStep(prevStep => Math.max(prevStep - 1, 0));
  };

  useEffect(() => {
    validateStep();
  }, [step]);

  useEffect(() => {
    validateStep();
  }, [steps]);

  const titles = [t('modal.select_service_type'), t('modal.new_service_name'), ' '];
  const alignment = ['center', 'start', 'center'];

  return (
    <Modal alignment={alignment[step]} show={show} onClose={onClose} title={titles[step]} size={{ width: '60%', height: '60%' }}>
      {steps.map((stepItem, index) => (
        <React.Fragment key={index}>
          {index === step && (
            <>
              {stepItem.header}
              {stepItem.content}
            </>
          )}
        </React.Fragment>
      ))}
      <ButtonContainer>
        {step > 0 && step !== steps.length - 1 && (
          <PreviousButton>
            <Button type='text' onClick={prevStep} label={t('common.previous')} />
          </PreviousButton>
        )}

        {step !== steps.length - 1 && (
          <NextButton>
            <Button
              type='primary'
              lightColor={BUTTON_COLORS.default}
              onClick={nextStep}
              disabled={!isStepValid}
              label={step === 1 ? t('modal.new_service_create') : t('common.next')}
            />
          </NextButton>
        )}
      </ButtonContainer>
    </Modal>
  );
};

export default WizardModale;

import styled from 'styled-components';
import { ContainerProps } from './Container.type';

const Div = styled.div<ContainerProps>`
  ${({ zIndex }) => zIndex && `z-index:${zIndex}`};
  display: ${({ flex, grid, none }) => (none ? 'none' : flex ? 'flex' : grid ? 'grid' : 'block')};
  flex-direction: ${({ column, grid, flex }) => flex && !grid && (column ? 'column' : 'row')};
  ${({ columnTemplate }) => columnTemplate && `grid-template-columns: ${columnTemplate};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}

  // Margins
  ${({ m }) => m && `margin: ${m};`}
  ${({ mt }) => mt && `margin-top: ${mt};`}
  ${({ mr }) => mr && `margin-right: ${mr};`}
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ ml }) => ml && `margin-left: ${ml};`}
  
  // Paddings
  ${({ p }) => p && `padding: ${p};`}
  ${({ pt }) => pt && `padding-top: ${pt};`}
  ${({ pr }) => pr && `padding-right: ${pr};`}
  ${({ pb }) => pb && `padding-bottom: ${pb};`}
  ${({ pl }) => pl && `padding-left: ${pl};`}
  
  & > *:not(:last-child) {
    ${({ mChild }) => mChild && `margin: ${mChild};`}
    ${({ mrChild }) => mrChild && `margin-right: ${mrChild};`}
    ${({ mlChild }) => mlChild && `margin-left: ${mlChild};`}
    ${({ mtChild }) => mtChild && `margin-top: ${mtChild};`}
    ${({ mbChild }) => mbChild && `margin-bottom: ${mbChild};`}
  }

  // Overflow properties
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
  ${({ overflowX }) => overflowX && `overflow-x: ${overflowX};`}
  ${({ overflowY }) => overflowY && `overflow-y: ${overflowY};`}
  
  ${({ gap }) => gap && `gap: ${gap};`}
  
  ${({ height }) => height && `height: ${height};`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ justifyItem }) => justifyItem && `justify-items: ${justifyItem};`}

  ${({ position }) => position && `position: ${position};`}
  
  ${({ border }) => border && `border: ${border};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}

  ${({ pointer }) => pointer && 'cursor: pointer;'}
  ${({ center }) => center && 'justify-content: center;'};
  ${({ bolder }) => bolder && 'font-weight:bold;'};

  ${({ right }) => right && `right:${right};`};
  ${({ left }) => left && `left:${left};`};
  ${({ top }) => top && `top:${top};`};
  ${({ bottom }) => bottom && `bottom:${bottom};`};

  ${({ flexGrow }) => flexGrow && `flex-grow:${flexGrow};`};
  ${({ flexWrap }) => flexWrap && `flex-wrap:${flexWrap};`};

  ${({ transition }) => transition && `transition:${transition};`};
  ${({ alignSelf }) => alignSelf && `align-self:${alignSelf};`};

  ${({ opacity }) => opacity && `opacity:${opacity};`};
`;

export default Div;

import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

export const FiltersCustom = styled(Button)<{ appliedFilters?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  :not(:first-child) {
    margin-left: 5px;
  }
  &.MuiButtonBase-root {
    border-radius: 5px;
    padding: 5px 15px;
    border: #326c82 solid 1px;
    color: #326c82;
  }

  ${({ appliedFilters }) =>
    !appliedFilters &&
    css`
      &.MuiButtonBase-root {
        color: black;
        font-size: 1rem;
        transition: all 0.2s ease-in-out;
        border: #c8c8c8 solid 1px;
        &:hover {
          border: #c8c8c8 solid 1px;
        }
      }
      ${TextCustom}{
        color: black;
      }
      }
    `}
`;

export const TextCustom = styled.h4`
  color: #326c82;
  margin: 0;
  text-transform: lowercase;
  font-size: 1rem;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const NbFilterCustom = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #326c82;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  font-size: 12px;
`;

export const ResetButtonCustom = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  color: #326c82;
  border: 1px solid #326c82;
  border-radius: 5px;
  padding: 5px 15px;
  & > *:first-child {
    margin-right: 0.75rem;
  }
  &:hover {
    background-color: rgba(25, 118, 210, 0.04);
    border: 1px solid #326c82;
  }
`;

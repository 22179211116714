import { useEffect, useState } from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MainLayout from 'src/layout/mainLayout';

import { SubContractorWizard } from '@pages/wizards';

import Login from '@components/login/login';
import { WizardSlides } from '@components/wizard/WizardSlides';
import Wizard from '@components/wizard/Wizard';
import { ModalWrapper } from '@components/modal-wrapper/ModalWrapper';

import { selectAccount, selectOriginalUser, selectUser, setAccount, setOriginalUser, setUser } from 'src/reducers/account.reducer';

import CustomRoute from '@routes/customRoute';
import { routes } from '@routes/routes';
import { paths } from '@routes/routes.constants';

import { getAccountByUserId } from '@utils/api';
import { getSessionToken, getSessionUserId } from '@utils/utils';

import './App.scss';
import './styles/fonts.css';

const App = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const account = useSelector(selectAccount);
  const user = useSelector(selectUser);
  const originalUser = useSelector(selectOriginalUser);
  const userToken = getSessionToken();
  const isAuthenticated = !!account && !!userToken;
  const [loginMode, setLoginMode] = useState('connection');
  const showWizard =
    account?.users.find(userItem => userItem._id === user?._id)?.role === 'accountOfficer' &&
    !account?.users.find(userItem => userItem._id === user?._id)?.wizardCompleted;

  useEffect(() => {
    const isPrivate = !!routes.find(route => route.path === location.pathname)?.private;
    try {
      (async () => {
        if (isPrivate) {
          let params = new URL(window.location.href).searchParams;
          let token = getSessionToken() ?? params.get('uto');
          let id = getSessionUserId() ?? params.get('uid');
          if (!id || !token || !isAuthenticated) {
            history.replace(paths.login);
            return;
          }
          const userId = JSON.parse(id);
          if (!account) {
            const accountData = await getAccountByUserId(userId);
            const userData = accountData?.users?.find(user => user._id === userId);
            dispatch(setUser(userData));
            dispatch(setAccount(accountData));
          }
        }
      })();
    } catch (error) {
      console.log('🚀 ~ error:', error);
    }
  }, [location.pathname]);

  const changeLanguage = lng => {
    if (lng) {
      i18n.changeLanguage(lng);
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('i18nextLng', lng);
      }
    }
  };

  useEffect(() => {
    user && changeLanguage(user?.language);
  }, [user]);

  useEffect(() => {
    if (originalUser) {
      dispatch(setUser(originalUser));
      dispatch(setOriginalUser(undefined));
    }
  }, []);

  return (
    <>
      <Switch>
        <CustomRoute
          path={'/sub-contractor/:subContractorId'}
          isAuthenticated={isAuthenticated}
          isPrivate={false}
          component={() => <SubContractorWizard />}
        />
        <CustomRoute
          path={paths.wizard}
          isAuthenticated={false}
          isPrivate={false}
          component={() => <Wizard slideList={WizardSlides(account, t)} />}
        />
        <CustomRoute
          path={paths.login}
          exact
          isAuthenticated={isAuthenticated}
          redirectPath={showWizard ? paths.wizard : paths.dashboardGroup}
          isPrivate={false}
          component={() => <Login mode={loginMode} />}
        />

        <MainLayout>
          {routes.map(route => (
            <CustomRoute
              key={route.path}
              path={route.path}
              exact={route.exact}
              isPrivate={route.private}
              component={route.component}
              isAuthenticated={isAuthenticated}
              redirectPath={paths.login}
            />
          ))}
        </MainLayout>
      </Switch>
      <ModalWrapper setLoginMode={setLoginMode} />
    </>
  );
};

export default App;

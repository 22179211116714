import { AvatarGroup, Stack } from '@mui/material';
import { COLORSV2 } from '@utils/constants';
import { IoChatbubblesOutline } from 'react-icons/io5';
import styled from 'styled-components';

export const DataTableContainer = styled.div<{ tableInventory?: boolean }>`
  border-radius: 15px;
  border: 1px solid var(--Gris-50, #f4f6f7);
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.09);

  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  ${({ tableInventory }) =>
    tableInventory &&
    `
      border-left: 1px solid #D9D9D98A;
      border-right: 1px solid #D9D9D98A;
      border-bottom-left-radius: 17px;
      border-bottom-right-radius: 17px;
      border-bottom: 1px solid #D9D9D98A;
      font-weight: 600;
    `}

  .data-table {
    overflow: auto;

    .row {
      align-items: center;
      column-gap: 0.75%;
      padding: 0.5rem;
    }
    .row:not(last-child) {
      margin-bottom: 0.5rem;
    }
    .row:not(first-child) {
      margin-top: 0.5rem;
    }
  }
`;

export const Row = styled.div<{ backlog?: boolean; headerRow?: boolean; hover?: boolean; rowInventory?: boolean; headerRowInventory?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.75%;
  padding: 0.5rem;

  color: ${COLORSV2.primary};

  font-family: Nexa Regular;

  &:not(last-child) {
    margin-bottom: 0.5rem;
  }
  &:not(first-child) {
    margin-top: 0.5rem;
  }
  ${({ backlog }) =>
    backlog &&
    `
    cursor: pointer;
    `}
  ${({ hover }) =>
    hover &&
    `&:hover {
    background-color: #80808012;
    border-radius: 0.625rem;
    cursor: pointer;
  }`}
  ${({ headerRow }) =>
    headerRow &&
    `
    height:3rem;
    padding-right: 14px;
    margin: 0px 10px;
    background-color: rgba(211, 224, 236, 0.31);

    color: ${COLORSV2.textDarkGrey};
    font-family: Nexa Bold;
    `}
  ${({ rowInventory }) =>
    rowInventory &&
    `
    color: #3B4B5F;
    font-size: 1.2em;
    font-weigth: 600;
    `}
  ${({ headerRowInventory }) =>
    headerRowInventory &&
    `
    color: ${COLORSV2.textDarkGrey};
    background: #F8FAFC;
    &:not(last-child) {
    margin-bottom: 0;
    }
    &:not(first-child) {
      margin-top: 0;
    }
    `}
`;

export const AvatarContainer = styled(AvatarGroup)`
  justify-content: flex-end;
`;

export const ChatBubbleIcon = styled(IoChatbubblesOutline)<{ active: boolean }>`
  ${({ active }) =>
    !active &&
    `
    opacity: 0.5;
`}
`;

export const CustomStack = styled(Stack)`
  align-items: center;
`;

export const NoDataContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;

export const HoverWarranties = styled.div<{ visibility: string }>`
  position: absolute;
  font-size: 0.8em;
  top: -40px;
  background: #5e778c;
  color: white;
  border-radius: 10px;
  padding: 5px;
  min-width: 125px;
  visibility: ${({ visibility }) => visibility};
  opacity: ${({ visibility }) => (visibility === 'visible' ? 1 : 0)};
  transition:
    visibility 0s,
    opacity 0.3s ease-in-out;
`;

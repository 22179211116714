import { Modal } from '../modal/Modal';

import styled from 'styled-components';
import { Button } from '../Button';
import { InputComponent } from '../form/InputComponent';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ModalRenameProductProps {
  show?: boolean;
  onClose: () => void;
  onRenameSurvey: (productName: string) => void;
  renamedProduct: { survey: any; version: any };
  isNewProductNameValid: (productName: string, productType: any) => boolean;
}

export const ModalRenameProduct = ({ show, onClose, onRenameSurvey, renamedProduct, isNewProductNameValid }: ModalRenameProductProps) => {
  const { t } = useTranslation();
  const [newProductName, setNewProductName] = useState(null);

  return (
    <>
      <Modal
        alignment={'center'}
        size={{ width: '50%' }}
        show={show}
        title={`${t('modal.rename_title_prefix')} ${renamedProduct?.version?.versionName} ${t('modal.rename_title_suffix')}`}
        onClose={onClose}>
        <BasicModalContainer>
          <InputComponent
            focus={true}
            placeholder={t('modal.new_name_placeholder')}
            sharable={false}
            label={t('modal.new_entity_name_label')}
            type={'text'}
            onChange={e => setNewProductName(e.target.value)}
            error={
              newProductName &&
              newProductName?.trim().length !== 0 &&
              !isNewProductNameValid(newProductName, renamedProduct?.survey) &&
              t('modal.name_already_used_error')
            }
            value={newProductName}
          />
          <ButtonContainer>
            <Button type='text' onClick={onClose} label={t('common.cancel')} />
            <Button
              type='primary'
              onClick={() => onRenameSurvey(newProductName)}
              label={t('common.confirm')}
              disabled={!isNewProductNameValid(newProductName, renamedProduct?.survey)}
            />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
    </>
  );
};

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

import styled from 'styled-components';

import { InputComponent } from '@components/form/InputComponent';
import { COLORSV2 } from '@utils/constants';

export const SubContractorName = styled.div`
  font-size: 1.3em;
`;

export const ContainerNameBottom = styled.div`
  margin-top: 10px;
  font-weight: 500;
  line-break: anywhere;
`;

export const Link = styled.a`
  color: #3b4b5f;
`;

export const LinkBold = styled.a`
  color: #3b4b5f;
  font-weight: 700;
`;

export const StyledInputComponent = styled(InputComponent)`
  width: 25rem;
  background: white;
  border-radius: 17px;
  .input input {
    height: 100% !important;
  }
`;

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8fafc;
  padding: 16px;
  margin-top: 27px;
  margin-bottom: 0;
  border: 1px solid #d9d9d98a;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const DownloadView = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
  text-decoration-line: underline;
  text-underline-position: from-font;
  color: #3b4b5f;
  gap: 9px;
  margin-right: 15px;
  transition: all 0.3s ease-out;

  ${({ active }) =>
    active &&
    `
  border: 1px solid ${COLORSV2.textDarkGrey};
  border-radius: 100px;
  padding: 5px 10px;
  `}
`;

export const DownloadTitle = styled.span`
  display: flex;
  align-items: center;
  font-family: 'Nunito';
  font-size: 1.6em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.76);
  span {
    margin-left: 6px;
  }
`;

export const DownloadText = styled.span`
  margin-top: 24px;
  font-family: 'Nunito';
  font-size: 1.3em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.76);
`;

export const Criteria = styled.div`
  margin-top: 17px;
  margin-right: 16px;
  background: #f8fafc;
  color: rgba(0, 0, 0, 0.76);
  border-radius: 15px;
  padding: 0 17px;
  font-size: 1.3em;
  font-family: 'Nunito';
  p {
    font-family: 'Nunito';
    font-size: 1.05em;
  }
  div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const List = styled.ul`
  padding: 0;
  li {
    list-style-type: none;
  }
`;

export const SubContractorNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import styled from 'styled-components';
import { COLORSV2 } from '@utils/constants';

import dipeeoLogo from '../../assets/icon.svg';
// TO CHANGE WHEN MARGOT WILL HAVE THE NEW LOGO SVG
// import { ReactComponent as DipeeoLogoText } from '@assets/logo-text-gradient.svg';
import dipeeoLogoText from '@assets/logo-text-gradient.png';

interface LogoProps {
  subtitle?: string;
  className?: string;
  text: boolean;
  onClick?: () => void;
  pointer?: boolean;
}

export const Logo = ({ subtitle, className, text, onClick, pointer }: LogoProps) => {
  return (
    <LogoContainer pointer={pointer} onClick={onClick} className={className}>
      {text ? <StyledLogoText src={dipeeoLogoText} alt='Logo Dipeeo' /> : <StyledLogo src={dipeeoLogo} alt='Logo Dipeeo' />}
      {/* {text ? <StyledLogoText /> : <img src={dipeeoLogo} alt='Logo Dipeeo' />} */}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </LogoContainer>
  );
};

// const StyledLogoText = styled(DipeeoLogoText)`
//   width: 120px;
//   height: auto;
//   display: block;
// `;

const StyledLogoText = styled.img`
  width: 150px;
  height: auto;
`;

const StyledLogo = styled.img`
  width: 40px;
  height: 40px;
`;

const LogoContainer = styled.div<{ pointer: boolean }>`
  position: relative;

  ${({ pointer }) => pointer && 'cursor: pointer;'}
`;

const Subtitle = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
  color: ${COLORSV2.primary};
  font-size: 0.8125em;
  font-family: Biennale;
  font-weight: 700;
`;

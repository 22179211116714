import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { InputComponent } from '../form/InputComponent';
import { Button } from '../Button';
import TitlePage from '../titlePage/TitlePage';

import { resetPassword, selectImpersonated, selectOriginalUser, selectUser, setUser, updateUser } from 'src/reducers/account.reducer';
import { clearContentfulActions } from 'src/reducers/contentful.reducer';

import { USER_ROLES } from 'src/utils/constants';
import { addEventTracking } from 'src/utils/utils';

import {
  ButtonSubmitContainer,
  FooterContainer,
  PersonalInformationsContainer,
  PersonalInformationsFormContainer,
  RowContainer,
  StyledInfos,
  Subtitle,
} from './PersonalInformations.styles';

const PersonalInformations = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const impersonated = useSelector(selectImpersonated);

  const user = useSelector(selectUser);
  const originalUser = useSelector(selectOriginalUser);
  const selectedUser = useMemo(
    () => (originalUser !== undefined ? user : impersonated?.users?.find(user => user.role === USER_ROLES.AccountOfficer) ?? user),
    [impersonated, user]
  );
  const [formData, setFormData] = useState({
    name: selectedUser?.name,
    firstName: selectedUser?.firstName,
    email: selectedUser?.email,
    phoneNumber: selectedUser?.phoneNumber,
    role: selectedUser?.role,
    language: selectedUser?.language,
  });
  const [isLangChanged, setIsLangChanged] = useState<boolean>(false)

  const handleUserInfosChange = (name, value, id, e) => {
    if (name.target) {
      value = name.target.value;
      name = name.target.name;
    }

    if (name === 'language') {
      if (selectedUser.language !== value)
        setIsLangChanged(true);
      else
        setIsLangChanged(false);
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const updatedUser = {
      ...selectedUser,
      ...formData,
    };
    try {
      const result = await dispatch(updateUser({ userId: updatedUser._id, data: formData })).unwrap();

      if (result.error) {
        throw new Error(result.error);
      }

      dispatch(setUser(updatedUser));
      if (isLangChanged) {
        dispatch(clearContentfulActions());
      }
      toast.success(t('personal_info.user_info_updated'));
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error(t('personal_info.user_info_update_failed'));
    }
  };

  const accountRoleOptions = [
    { label: t('common.administrator'), value: USER_ROLES.ADMIN },
    { label: t('common.gdpr_referent'), value: USER_ROLES.AccountOfficer },
    { label: t('common.client'), value: USER_ROLES.Guest },
    { label: t('common.sales'), value: USER_ROLES.Sales },
  ];

  const accountLanguageOptions = [
    { label: t('common.french'), value: 'fr' },
    { label: t('common.english'), value: 'en' },
  ];

  const handleResetPassword = async () => {
    addEventTracking('Password', 'Reset');
    await dispatch(resetPassword({ email: selectedUser.email }));
    toast.info(t('personal_info.password_reset_info'));
  };

  return (
    <PersonalInformationsContainer id='personal-informations-container'>
      <TitlePage title={t('personal_info.personal_info')} />

      <Subtitle>{t('personal_info.account_info')}</Subtitle>
      <PersonalInformationsFormContainer>
        <RowContainer>
          <InputComponent
            sharable={false}
            type={'text'}
            label={t('common.first_name')}
            name={'firstName'}
            onChange={handleUserInfosChange}
            value={formData.firstName}
          />
          <InputComponent
            sharable={false}
            type={'text'}
            label={t('common.name')}
            name={'name'}
            onChange={handleUserInfosChange}
            value={formData.name}
          />
        </RowContainer>
        <RowContainer>
          <InputComponent
            disabled={!(selectedUser.role === USER_ROLES.AccountOfficer || selectedUser.role === USER_ROLES.ADMIN)}
            sharable={false}
            type={'text'}
            label={t('common.email')}
            name={'email'}
            onChange={handleUserInfosChange}
            value={formData.email}
          />
          <InputComponent
            sharable={false}
            type={'text'}
            label={t('common.phone')}
            name={'phoneNumber'}
            onChange={handleUserInfosChange}
            value={formData.phoneNumber}
          />
        </RowContainer>
        <RowContainer>
          <InputComponent
            disabled={true}
            onChange={handleUserInfosChange}
            type='select'
            name={'role'}
            options={accountRoleOptions}
            label={t('common.user_role')}
            sharable={false}
            value={accountRoleOptions.find(option => option.value === formData.role)}
          />
        </RowContainer>
        <RowContainer>
          <InputComponent
            onChange={handleUserInfosChange}
            type='select'
            name={'language'}
            options={accountLanguageOptions}
            label={t('common.language')}
            sharable={false}
            value={accountLanguageOptions.find(option => option.value === formData.language)}
          />
        </RowContainer>
        <ButtonSubmitContainer>
          <Button type='primary' label={t('personal_info.save_changes')} onClick={handleSubmit} />
        </ButtonSubmitContainer>
      </PersonalInformationsFormContainer>

      <Subtitle>{t('personal_info.reset_password')}</Subtitle>

      <StyledInfos
        action={{ text: t('personal_info.reset_password_action'), onClick: handleResetPassword }}
        text={t('personal_info.reset_password_info')}
      />

      <FooterContainer>
        <a target='_blank' rel='noopener noreferrer' href={'/CGU Dipeeo app.pdf'}>
          {t('menu.terms_of_use')}
        </a>
        <a target='_blank' rel='noopener noreferrer' href={'/Dipeeo - FR -Politique_de_confidentialite -app_en_ligne.pdf'}>
          {t('menu.privacy_policy')}
        </a>
      </FooterContainer>
    </PersonalInformationsContainer>
  );
};

export default PersonalInformations;

/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ActionContainer,
  Content,
  DashboardGenerationContainer,
  DocumentsList,
  EntitiesContainer,
  EntityContainer,
  EntityTitle,
  FixInputContainer,
  InputContainer,
  Label,
  Tab,
  TabContainer,
  TopContainer,
  VersionChoiceContainer,
  VersionItem,
  VersionItemWarning,
  LoaderContainer,
} from './DashboardGeneration_.style';
import _ from 'lodash';
import { InputComponent } from '../form/InputComponent';
import { Button } from '../Button';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';
import { selectAccount, selectSelectedAccount } from '../../reducers/account.reducer';
import { ACCOUNT_TYPES } from '@utils/constants';
import { getOneDoc } from '../docGeneration/docx-utils';
import { getOneExcel } from '../docGeneration/excel-utils';
import Loader from '@components/loader/loader';
import { getAuthorizedSurveys } from '@utils/utils';
import { useDocxOptions } from '@hooks/useDocxOptions';
import { useRegistriesOptions } from '@hooks/useRegistriesOptions';
import { useLinkedSurveys } from '@hooks/useLinkedSurveys';
import { GENERATION_MODES, INITIAL_STATE } from '@utils/generate.constants';
import { isGenerationReady } from '@utils/generate.utils';
import { t } from 'i18next';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_GENERATION_MODE':
      return { ...state, generationMode: action.payload };
    case 'SET_TARGET_GENERATION':
      return { ...state, targetGeneration: action.payload };
    case 'SET_TARGET_ZIP_CONTENT':
      return { ...state, targetZipContent: action.payload };
    case 'SET_LANG':
      return { ...state, lang: action.payload };
    case 'SET_LINKED_SURVEYS':
      return { ...state, linkedSurveys: action.payload };
    case 'SET_ONLY_SELECTED_PRODUCTS':
      return { ...state, onlySelectedProducts: action.payload };
    default:
      return state;
  }
};

export const DashboardGeneration = () => {
  //redux
  const contentfulData = useSelector(selectAllContentfulData);
  const account = useSelector(selectAccount);
  const selectedAccount = useSelector(selectSelectedAccount);

  // 🔹 Local state
  const [targetZipContent, setTargetZipContent] = useState([]);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  // 🔹 Hooks to retrieve options
  const docxOptions = useDocxOptions(state.lang);
  const registriesOptions = useRegistriesOptions(state.lang);
  let linkedSurveys = useLinkedSurveys(
    state.targetGeneration?.id,
    state.targetGeneration?.entityName,
    state.generationMode,
    contentfulData,
    selectedAccount
  );

  // 🔹 HANDLERS (Event handlers)
  const handleLoading = loadingStatus => {
    dispatch({ type: 'SET_LOADING', payload: loadingStatus });
  };

  const handleChangeGenerationMode = mode => {
    dispatch({ type: 'SET_GENERATION_MODE', payload: mode });
  };

  const updateSelectedDocument = (name, value) => {
    let newTarget = docxOptions.find(opt => opt.value === value);
    const newTargetGeneration = {
      ...state.targetGeneration,
      id: newTarget.value || undefined,
      name: newTarget.label || undefined,
    };
    dispatch({ type: 'SET_TARGET_GENERATION', payload: newTargetGeneration });
  };

  const handleTargetRegistry = (name, value) => {
    let newTarget = registriesOptions.find(opt => opt.value === value);
    const newTargetGeneration = {
      ...state.targetGeneration,
      id: newTarget.value || undefined,
      name: newTarget.label || undefined,
    };
    dispatch({ type: 'SET_TARGET_GENERATION', payload: newTargetGeneration });
  };

  const handleTargetGeneration = (name, value) => {
    const newTargetGeneration = { ...state.targetGeneration, [name]: value };
    dispatch({ type: 'SET_TARGET_GENERATION', payload: newTargetGeneration });
  };

  const handleTargetEntity = (targetName, value) => {
    let { name, id } = JSON.parse(value);
    const newTargetGeneration = { ...state.targetGeneration, entityName: name, entityId: id };
    dispatch({ type: 'SET_TARGET_GENERATION', payload: newTargetGeneration });
  };

  const handleTargetLang = (name, value) => {
    dispatch({ type: 'SET_LANG', payload: value });
  };

  const handleDocxSubmit = async () => {
    handleLoading(true);
    let values = computeSelectedValues();
    await getOneDoc(
      values,
      state.targetGeneration.id,
      state.targetGeneration.name,
      state.targetGeneration.entityName,
      selectedAccount._id,
      state.lang
    );
    handleLoading(false);
  };

  const computeSelectedValues = () => {
    let entity = selectedAccount.entities.find(entity => entity.name === state.targetGeneration.entityName);

    if (!entity.responses.length) {
      return;
    }
    let authorizedSurveys = getAuthorizedSurveys();
    let activatedAuthorizedSurveys = authorizedSurveys.filter(
      authorizedSurvey => !entity.responses.some(response => response.surveyId === authorizedSurvey.id && response.disabled)
    );

    return activatedAuthorizedSurveys.reduce((acc, survey) => {
      let surveyResponses;

      if (!survey.versioning.enabled) {
        surveyResponses = entity.responses.find(response => response.surveyId === survey.id)?.versions[0]?.versionResponses;
      } else {
        let targetSurveyName = state.targetGeneration[survey.id];
        surveyResponses = state.onlySelectedProducts
          ? entity.responses
              .find(surveyResponses => surveyResponses.surveyId === survey.id)
              ?.versions.find(version => version.versionName === targetSurveyName)?.versionResponses
          : entity.responses.find(surveyResponses => surveyResponses.surveyId === survey.id)?.versions[0]?.versionResponses;
      }

      return { ...acc, ...surveyResponses };
    }, {});
  };

  const handleExcelSubmit = async () => {
    handleLoading(true);
    let values = computeSelectedValues();
    await getOneExcel(
      values,
      state.targetGeneration.id,
      state.targetGeneration.name,
      state.targetGeneration.entityName,
      state.targetGeneration.entityId,
      selectedAccount._id
    );
    handleLoading(false);
  };

  const getEntitiesOptions = () =>
    selectedAccount?.entities?.map(entity => ({
      label: entity.name.replaceAll('_', ' '),
      value: JSON.stringify({
        name: entity.name,
        id: entity._id,
      }),
    }));

  const getLangOptions = () => [
    { value: 'FR', label: 'Français' },
    { value: 'EN', label: 'Anglais' },
  ];

  const toggleZipContent = (document, entity, checked, docType) => {
    let targetZipCopy = _.cloneDeep(targetZipContent);
    let targetEntity = targetZipCopy.find(currentEntity => currentEntity.name === entity.name);

    document = { ...document, type: docType };

    if (!checked) {
      targetEntity.includedDocuments = targetEntity.includedDocuments.filter(currentDocument => currentDocument.value !== document.value);
      setTargetZipContent(targetZipCopy);
      return;
    }

    if (!targetEntity) {
      targetZipCopy.push({
        name: entity.name,
        includedDocuments: [document],
      });
    } else {
      targetEntity.includedDocuments.push(document);
    }

    setTargetZipContent(targetZipCopy);
    return;
  };

  const isCheckedZipContent = (document, entity) => {
    let targetEntity = targetZipContent.find(currentEntity => currentEntity.name === entity.name);
    if (!targetEntity) {
      return false;
    }
    return targetEntity.includedDocuments.some(currentDocument => currentDocument.label === document.label);
  };

  const getInputValue = link => {
    if (!state.targetGeneration[link.survey.id]) {
      return { label: '', value: '' };
    }
    return { label: state.targetGeneration[link.survey.id], value: state.targetGeneration[link.survey.id] };
  };

  return (
    <DashboardGenerationContainer>
      <TopContainer>
        <TabContainer>
          <Tab current={state.generationMode === GENERATION_MODES.WORD} onClick={() => handleChangeGenerationMode(GENERATION_MODES.WORD)}>
            WORD
          </Tab>
          {account?.accountType === ACCOUNT_TYPES.ADMIN && (
            <>
              <Tab current={state.generationMode === GENERATION_MODES.EXCEL} onClick={() => handleChangeGenerationMode(GENERATION_MODES.EXCEL)}>
                EXCEL
              </Tab>
            </>
          )}
        </TabContainer>
      </TopContainer>
      <Content>
        {state.generationMode === GENERATION_MODES.WORD && (
          <>
            <FixInputContainer>
              <InputContainer>
                <InputComponent
                  key={'word_choice'}
                  name={'word_choice'}
                  type={'select'}
                  label={t('adminGeneration.inputLabel.docChoice')}
                  options={docxOptions}
                  onChange={updateSelectedDocument}
                  clearable={true.toString()}
                  value={docxOptions.filter(value => {
                    return value.value === state.targetGeneration?.id;
                  })}
                  sharable={false}
                />
              </InputContainer>
              <InputContainer>
                <InputComponent
                  key={'entity_choice'}
                  name={'entity_choice'}
                  type={'select'}
                  label={t('adminGeneration.inputLabel.companyChoice')}
                  options={getEntitiesOptions()}
                  onChange={handleTargetEntity}
                  sharable={false}
                />
              </InputContainer>
              <InputContainer>
                <InputComponent
                  key={'lang_choice'}
                  name={'lang_choice'}
                  type={'select'}
                  label={t('adminGeneration.inputLabel.langChoice')}
                  options={getLangOptions()}
                  onChange={handleTargetLang}
                  sharable={false}
                  value={{ value: 'FR', label: 'Français' }}
                />
              </InputContainer>
            </FixInputContainer>
            <VersionChoiceContainer>
              {state.loading ? (
                <LoaderContainer>
                  <Loader loaderOnly={true} />
                </LoaderContainer>
              ) : (
                state.targetGeneration?.entityName && (
                  <>
                    {linkedSurveys.length > 0 && (
                      <Label htmlFor={'onlySelectedProducts'}>
                        <input
                          defaultChecked={state.onlySelectedProducts}
                          id={'onlySelectedProducts'}
                          type='checkbox'
                          onChange={() =>
                            dispatch({
                              type: 'SET_ONLY_SELECTED_PRODUCTS',
                              payload: !state.onlySelectedProducts,
                            })
                          }
                        />
                        {t('adminGeneration.inputLabel.onlySelectedProducts')}
                      </Label>
                    )}

                    {linkedSurveys.length > 0 &&
                      linkedSurveys?.map((link, index) => {
                        return (
                          <VersionItem key={'doc_version_item_' && index}>
                            <InputComponent
                              key={link.survey.name + index}
                              name={link.survey.id}
                              type={'select'}
                              label={link.survey.name}
                              options={link.versions.map(version => ({
                                label: version.replaceAll('_', ' '),
                                value: version,
                              }))}
                              onChange={handleTargetGeneration}
                              value={getInputValue(link)}
                              sharable={false}
                              clearable={true.toString()}
                            />
                            {link.versions.length > 1 && <VersionItemWarning>{t('adminGeneration.warningMessage')}</VersionItemWarning>}
                          </VersionItem>
                        );
                      })}
                  </>
                )
              )}
            </VersionChoiceContainer>
          </>
        )}
        {state.generationMode === GENERATION_MODES.EXCEL && (
          <>
            <FixInputContainer>
              <InputContainer>
                <InputComponent
                  name={'excel_choice'}
                  type={'select'}
                  label={t('adminGeneration.inputLabel.registryChoice')}
                  options={registriesOptions}
                  onChange={handleTargetRegistry}
                  clearable={true.toString()}
                  value={registriesOptions.filter(value => value.value === state.targetGeneration?.id)}
                  sharable={false}
                />
              </InputContainer>
              <InputContainer>
                <InputComponent
                  key={'entity_choice'}
                  name={'entity_choice'}
                  type={'select'}
                  label={t('adminGeneration.inputLabel.companyChoice')}
                  options={getEntitiesOptions()}
                  onChange={handleTargetEntity}
                  sharable={false}
                />
              </InputContainer>
              <InputContainer>
                <InputComponent
                  key={'lang_choice'}
                  name={'lang_choice'}
                  type={'select'}
                  label={t('adminGeneration.inputLabel.langChoice')}
                  options={getLangOptions()}
                  onChange={handleTargetLang}
                  sharable={false}
                  value={{ value: 'FR', label: 'Français' }}
                />
              </InputContainer>
            </FixInputContainer>
            <VersionChoiceContainer>
              {state.loading ? (
                <LoaderContainer>
                  <Loader loaderOnly={true} />
                </LoaderContainer>
              ) : (
                state.targetGeneration?.entityName && (
                  <>
                    {linkedSurveys.length > 0 && (
                      <Label htmlFor={'onlySelectedProducts'}>
                        <input
                          defaultChecked={state.onlySelectedProducts}
                          id={'onlySelectedProducts'}
                          type='checkbox'
                          onChange={() =>
                            dispatch({
                              type: 'SET_ONLY_SELECTED_PRODUCTS',
                              payload: !state.onlySelectedProducts,
                            })
                          }
                        />
                        {t('adminGeneration.inputLabel.onlySelectedProducts')}
                      </Label>
                    )}
                    {linkedSurveys.length > 0 &&
                      linkedSurveys?.map((link, index) => {
                        return (
                          <VersionItem key={'excel_version_item_' && index}>
                            <InputComponent
                              key={link.survey.name + index}
                              name={link.survey.id}
                              type={'select'}
                              label={link.survey.name}
                              options={link.versions.map(version => ({
                                label: version.replaceAll('_', ' '),
                                value: version,
                              }))}
                              onChange={handleTargetGeneration}
                              value={getInputValue(link)}
                              sharable={false}
                            />
                            {link.versions.length > 1 && <VersionItemWarning>{t('adminGeneration.warningMessage')}</VersionItemWarning>}
                          </VersionItem>
                        );
                      })}
                  </>
                )
              )}
            </VersionChoiceContainer>
          </>
        )}
        {state.generationMode === GENERATION_MODES.ALL && (
          <EntitiesContainer>
            {selectedAccount.entities.map((entity, index) => (
              <EntityContainer key={index}>
                <EntityTitle>{entity.name}</EntityTitle>
                <DocumentsList>
                  {selectedAccount?.actionRights.documents.map((doc, index) => (
                    <label key={index + doc.id + entity.name} htmlFor={`docID-${entity.name + doc.id}`}>
                      <input
                        checked={isCheckedZipContent(doc, entity)}
                        type='checkbox'
                        name={`doc-${doc.id}`}
                        id={`docID-${entity.name + doc.id}`}
                        onChange={e => toggleZipContent(doc, entity, e.target.checked, 'docx')}
                      />
                      {doc.label}
                    </label>
                  ))}
                  {selectedAccount?.actionRights.registries.map((registry, index) => (
                    <label key={index + registry.id + entity.name} htmlFor={`registryId-${entity.name + registry.id}`}>
                      <input
                        checked={isCheckedZipContent(registry, entity)}
                        type='checkbox'
                        name={`registry-${registry.id}`}
                        id={`registryId-${entity.name + registry.id}`}
                        onChange={e => toggleZipContent(registry, entity, e.target.checked, 'excel')}
                      />
                      {registry.label}
                    </label>
                  ))}
                </DocumentsList>
              </EntityContainer>
            ))}
          </EntitiesContainer>
        )}
        <ActionContainer>
          {(state.generationMode === GENERATION_MODES.WORD || state.generationMode === GENERATION_MODES.EXCEL) && (
            <Button
              disabled={!isGenerationReady(state)}
              onClick={state.generationMode === GENERATION_MODES.WORD ? handleDocxSubmit : handleExcelSubmit}
              label={t('adminGeneration.buttonLabel.generateDoc')}
            />
          )}
          {/*{generationMode === GENERATION_MODES.ALL && <Button onClick={getCustomZip} label={'Générer le ZIP'} />}*/}
        </ActionContainer>
      </Content>
    </DashboardGenerationContainer>
  );
};

import styled from 'styled-components';
import { CgClose } from 'react-icons/cg';

export const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const InfoButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  background: transparent;
  border-radius: 50%;
  border: none;
  cursor: pointer;
`;

export const PopupContainer = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #eaeaea;
  width: 300px;

  & * {
    &::-webkit-scrollbar {
      width: 16px !important;
      background-color: #f5f7f9 !important;
      border-radius: 8px !important;
      border: 6px solid transparent !important;
      background-clip: padding-box !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #326c82 !important;
      border-radius: 8px !important;
      border: 6px solid transparent !important;
      background-clip: padding-box !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #265569 !important;
      border: 6px solid transparent !important;
      background-clip: padding-box !important;
    }

    &::-webkit-scrollbar-track {
      background: transparent !important;
      box-shadow: none !important;
    }
  }
`;

export const PopupHeader = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PopupTitle = styled.h3`
  font-size: 14px;
  color: #326c82;
  margin: 0;
`;

export const CloseButtonWrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #666;
  }
`;

export const StyledCloseIcon = styled(CgClose)`
  font-size: 20px;
  color: #999;
`;

export const PopupContent = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding: 12px 16px;
  padding-top: 0;
`;

export const PopupItem = styled.div`
  font-size: 14px;
  color: #333;
  padding: 12px 0 4px 0;
  border-bottom: 1px solid #f0f0f0;
`;

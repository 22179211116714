import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { FileExplorer } from '../file-explorer/FileExplorer';
import { COLORSV2 } from '@utils/constants';

const MyDocuments = () => {
  const { t } = useTranslation();

  return (
    <MyDocumentsContainer>
      <Title>{t('myDocuments.title')}</Title>
      <FileExplorer />
    </MyDocumentsContainer>
  );
};

export default MyDocuments;

const MyDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 80px 80px 50px 80px;
  height: calc(100% - 130px);
  width: calc(100% - 160px);
  background-color: ${COLORSV2.lightPink};
  font-family: Biennale;
  color: ${COLORSV2.primary};
`;

const Title = styled.div`
  font-family: 'Biennale SemiBold';
  font-size: 30px;
  display: flex;
`;

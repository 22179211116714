import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { selectSelectedCollaborator, setSelectedCollaborator, setSidenavState } from '../../reducers/app.reducer';
import { selectActiveAccount } from '../../reducers/account.reducer';

import { paths } from '@routes/routes.constants';

import { COLORS, COLORSV2 } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { IoSearchOutline } from 'react-icons/io5';

export const CollaboratorsList = ({}) => {
  const { t } = useTranslation();
  const activeAccount = useSelector(selectActiveAccount);
  const selectedCollaborator = useSelector(selectSelectedCollaborator);
  const [collaborators, setCollaborators] = useState([]);
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const [search, setSearch] = useState(null);

  const handleSearchChange = e => {
    const value = e.target.value;
    setSearch(value === '' ? null : value);
  };

  const getFilteredAccounts = () =>
    !isSearchedString()
      ? collaborators
      : collaborators.filter(collaborator =>
          [collaborator?.name, collaborator?.firstName]
            .join(' ')
            ?.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            ?.includes(
              search
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
        );

  const handleSelectCollaborator = collaborator => {
    dispatch(setSidenavState(false));
    dispatch(setSelectedCollaborator(collaborator));
    history.push(paths.collaborators);
  };

  const isSearchedString = () => search !== null && search !== '';

  useEffect(() => {
    setCollaborators(activeAccount?.users.filter(user => user.role === 'guest'));
  }, [activeAccount]);

  return (
    <UserListContainer id='user-list-container'>
      <SearchContainer>
        <IoSearchOutline />
        <input onChange={handleSearchChange} placeholder={t('collaborators.searchCollaborators')} type='text' />
      </SearchContainer>
      <ListSlider>
        <List>
          {getFilteredAccounts()?.map((collaborator, index) => (
            <User key={index} onClick={() => handleSelectCollaborator(collaborator)} selected={selectedCollaborator?._id === collaborator._id}>
              <span>{collaborator?.firstName ? collaborator.firstName + ' ' + collaborator?.name : collaborator.name || collaborator.email}</span>
            </User>
          ))}
          {collaborators?.length === 0 && <span className={'empty'}>{t('collaborators.noCollaborators')}</span>}
        </List>
      </ListSlider>
    </UserListContainer>
  );
};

const ListSlider = styled.div`
  overflow-y: auto;

  padding-left: 30px;
  position: relative;
  flex: 1;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  position: relative;
  padding-right: 15px;

  &:before {
    content: '';
    position: absolute;
    left: -20px;
    width: 2px;
    background-color: ${COLORS.MediumGrey};
    bottom: 30px;
    top: 0;
    z-index: 999;
  }

  span.empty {
    color: ${COLORS.Grey};
  }
`;

const User = styled.div<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? '#0f3e60' : COLORS.Black)};
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 1.3em;
  font-weight: 400;
  color: ${COLORS.Black};
  font-family: 'Roboto', sans-serif;
  position: relative;
  width: fit-content;

  span {
    transition: margin-left 0.2s ease-in-out;
    background-color: ${({ selected }) => (selected ? '#ededed' : 'transparent')};
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 5px;

    > span {
      font-size: 1rem;
      color: ${COLORS.MediumGrey};
    }
  }

  &:hover {
    span {
      margin-left: 3px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 15px;
    left: -20px;
    top: 55%;
    transform: translateY(-100%);
    border-bottom-left-radius: 10px;
    border-left: 2px solid ${COLORS.MediumGrey};
    border-bottom: 2px solid ${COLORS.MediumGrey};
  }
`;

const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);
  row-gap: 20px;
  flex: 1;
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: fit-content;
  width: 100%;

  color: ${COLORSV2.textLightGrey};

  input {
    margin-left: 10px;

    font-family: 'Nexa';
    font-size: 1.2em;
    width: 100%;
    padding: 10px 0 10px 35px;

    border-radius: 25px;
    border: 1px solid ${COLORSV2.border};
    background: ${COLORSV2.white};

    &:focus-visible {
      outline: none;
    }
  }

  svg {
    font-size: 20px;
    position: absolute;
    left: 20px;
    top: 10px;
  }
`;

import { PageWrapperProps } from './PageWrapper.type';
import { Content, PageTitle } from './PageWrapper_.style';

const PageWrapper = ({
  children,
  selectedView,
  showPageTitle,
  applyPadding = false,
  customPadding = null,
  position,
  customTitleStyle,
}: PageWrapperProps) => {
  return (
    <Content applyPadding={applyPadding} customPadding={customPadding} position={position}>
      {showPageTitle && <PageTitle style={customTitleStyle}>{selectedView.label}</PageTitle>}
      {children}
    </Content>
  );
};

export default PageWrapper;
